import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const AuthUtils = {
    /**
     * Checks if the user's email is verified
     * @param {boolean} forceServerCheck - Force check with server instead of using token
     * @returns {Promise<boolean>} True if verified, false otherwise
     */
    isEmailVerified: async (forceServerCheck = false) => {
        try {
            const token = localStorage.getItem('token');

            if (!token) {
                return false;
            }

            // If we're not forcing a server check, try to use token claims first
            if (!forceServerCheck) {
                try {
                    const decoded = jwtDecode(token);
                    if (decoded && 'EmailConfirmed' in decoded) {
                        return decoded.EmailConfirmed === 'True';
                    }
                } catch (error) {
                    console.warn('Failed to decode token', error);
                }
            }

            // Always perform API check to get the most current status
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/dashboard/access`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.data &&
                response.data.hasAccess === false &&
                response.data.reason === 'email_not_verified') {
                return false;
            }

            return true;
        } catch (error) {
            console.error('Error checking email verification status:', error);
            // Return true by default to prevent blocking access if check fails
            return true;
        }
    },

    /**
     * Gets the current user's email
     * @returns {Promise<string>} User's email or empty string
     */
    getUserEmail: async () => {
        try {
            const token = localStorage.getItem('token');

            if (!token) {
                return '';
            }

            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/auth/profile`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            return response.data?.email || '';
        } catch (error) {
            console.error('Error fetching user email:', error);
            return '';
        }
    },

    /**
     * Resends the verification email
     * @param {string} email User's email
     * @returns {Promise<Object>} Response object
     */
    resendVerificationEmail: async (email) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/auth/resend-verification`,
                { email }
            );

            return {
                success: true,
                message: response.data.message || 'Verification email sent successfully.'
            };
        } catch (error) {
            console.error('Error resending verification email:', error);
            return {
                success: false,
                message: error.response?.data?.message || 'Failed to send verification email.'
            };
        }
    },

    /**
     * Checks if verification has completed since login
     * Without causing a page reload loop
     */
    checkAndRefreshVerificationStatus: async () => {
        try {
            // Check if we've recently attempted a refresh to prevent loops
            const lastRefreshAttempt = localStorage.getItem('lastVerificationRefreshAttempt');
            const now = Date.now();

            // Only attempt refresh once every 5 minutes
            if (lastRefreshAttempt && (now - Number(lastRefreshAttempt)) < 5 * 60 * 1000) {
                return false;
            }

            // First check token claim
            const token = localStorage.getItem('token');
            if (!token) return false;

            let tokenSaysVerified = false;
            try {
                const decoded = jwtDecode(token);
                tokenSaysVerified = (decoded && 'EmailConfirmed' in decoded) ?
                    decoded.EmailConfirmed === 'True' : false;
            } catch (error) {
                console.warn('Failed to decode token', error);
            }

            // If token already says verified, nothing to do
            if (tokenSaysVerified) {
                return true;
            }

            // Now check with server
            const serverResponse = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/dashboard/access`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }
            );

            const serverVerified = !(serverResponse.data &&
                serverResponse.data.hasAccess === false &&
                serverResponse.data.reason === 'email_not_verified');

            // If server says verified but token disagrees
            if (serverVerified && !tokenSaysVerified) {
                console.log('Email verification detected, marking verification state...');

                // Store the fact we're verified in localStorage
                localStorage.setItem('emailVerifiedServerSide', 'true');
                localStorage.setItem('lastVerificationRefreshAttempt', now.toString());

                // Instead of reloading which causes loops, we'll just return the server value
                // A proper fix would involve backend support for token refresh
                return true;
            }

            return serverVerified;
        } catch (error) {
            console.error('Error checking verification status:', error);
            return false;
        }
    },

    /**
     * Get the local email verification status including server-side state
     * This is used to bypass token limitations
     */
    getLocalVerificationStatus: () => {
        // Check token first
        const token = localStorage.getItem('token');
        if (!token) return false;

        try {
            const decoded = jwtDecode(token);
            if (decoded && 'EmailConfirmed' in decoded && decoded.EmailConfirmed === 'True') {
                return true;
            }
        } catch (error) {
            console.warn('Failed to decode token for verification check', error);
        }

        // If token doesn't say verified, check if we have a local override from server check
        return localStorage.getItem('emailVerifiedServerSide') === 'true';
    }
};

export default AuthUtils;