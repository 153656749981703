import React, {useState, useEffect, useCallback, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
import axios from 'axios';
import _ from 'lodash';
import {
    ArrowRight,
    Search,
    MapPin,
    Clock,
    Bell,
    Filter,
    X,
    ChevronRight,
    AlertCircle,
    Check
} from 'lucide-react';
import Navbar from './NavMenu';
import Footer from './Footer';
import LocationDropdown from "./Helpers/LocationDropdown";

const Home = () => {
    const navigate = useNavigate();
    const canonicalUrl = 'https://dwellio.co.uk/';
    const [searchLocation, setSearchLocation] = useState('');
    const [locationSuggestions, setLocationSuggestions] = useState([]);
    const [loadingLocations, setLoadingLocations] = useState(false);
    const [searchError, setSearchError] = useState('');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const inputRef = useRef(null);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    useCallback(
        _.debounce(async (query) => {
            if (!query || query.length < 2) {
                setLocationSuggestions([]);
                return;
            }
            setLoadingLocations(true);
            try {
                const response = await axios.get('https://localhost:7054/api/Places/autocomplete', {
                    params: {input: query},
                });
                if (response.data.status === 'OK' && response.data.predictions) {
                    setLocationSuggestions(response.data.predictions);
                } else {
                    setLocationSuggestions([]);
                }
            } catch (error) {
                console.error('Error fetching location suggestions:', error);
                setLocationSuggestions([]);
            }
            setLoadingLocations(false);
        }, 300),
        []
    );
    const handleLocationSelect = (suggestion) => {
        setSearchLocation(suggestion.structured_formatting?.main_text || suggestion.name);
        setSearchError('');
    };

    const handleSearch = () => {
        if (!searchLocation || !searchLocation.trim()) {
            setSearchError('Please enter a location to search');
            return;
        }
        navigate(`/property-search?location=${encodeURIComponent(searchLocation)}`);
    };

    return (
        <>
            <Helmet>
                <title>Dwellio - Get Rental Property Alerts | Find Your Perfect Rental Before Anyone Else</title>
                <meta name="description"
                      content="Get instant alerts for new rental properties in your desired location. Be the first to view and secure your ideal home with Dwellio's real-time property notifications across the UK."/>
                <link rel="canonical" href={canonicalUrl}/>
            </Helmet>
            <Navbar/>
            <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
                <section className="relative min-h-screen md:min-h-[80vh]" aria-label="Hero section">
                    <div
                        className="absolute inset-0 bg-cover bg-center"
                        style={{
                            backgroundImage: `url('${isMobile ? '/mobile-bg.jpg' : '/hero-bg.jpg'}')`,
                        }}
                        role="img"
                        aria-label="Modern rental property interior background"
                    >
                        <div
                            className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/50 to-black/60 md:from-black/50 md:via-black/40 md:to-black/50"></div>
                    </div>

                    <div className="relative z-10 max-w-7xl mx-auto px-4 pt-12 md:pt-24 pb-16 text-white">
                        <div className="text-center mb-8">
                            <h1 className="text-4xl md:text-6xl font-bold mb-4">
                                Find your perfect rental<br/>
                                <span className="text-blue-400">before anyone else sees it</span>
                            </h1>

                            <p className="text-xl md:text-2xl mb-2 mt-4">
                                Get alerts when new properties match your requirements
                            </p>
                        </div>

                        <div className="max-w-3xl mx-auto mb-8">
                            <div className="bg-white/10 backdrop-blur-sm p-6 rounded-lg shadow-lg">
                                <div className="flex flex-col mb-2">
                                    <label htmlFor="location-search" className="text-lg font-medium mb-2 text-center">
                                        Where are you looking to rent?
                                    </label>

                                    <LocationDropdown onLocationSelect={handleLocationSelect}/>

                                    {searchError && (
                                        <div
                                            className="mb-4 p-2 bg-red-500/20 border border-red-500/30 text-white rounded-lg flex items-center mt-2">
                                            <AlertCircle className="h-4 w-4 mr-2 flex-shrink-0"/>
                                            <span>{searchError}</span>
                                        </div>
                                    )}

                                    <button
                                        onClick={handleSearch}
                                        className="w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-3 px-4 rounded-lg transition-colors flex items-center justify-center mt-4"
                                    >
                                        <Search className="h-5 w-5 mr-2"/>
                                        Get Property Alerts
                                    </button>

                                    <p className="text-sm text-center mt-3 text-blue-100">
                                        Free 7-day trial available
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="relative z-10 max-w-7xl mx-auto px-4 pt-12 md:pt-24 pb-16 text-white">
                            <div className="text-center mb-8">
                                <p className="text-lg mb-6">
                                    Our system monitors the UK's largest property websites 24/7 so you don't have to
                                </p>
                            </div>
                        </div>
                            {/* How It Works Steps */}
                            <div className="mt-16 grid grid-cols-1 md:grid-cols-4 gap-6">
                                {[
                                    {
                                        title: "Set Your Preferences",
                                        description: "Tell us what you're looking for",
                                        icon: MapPin
                                    },
                                    {
                                        title: "We Monitor 24/7",
                                        description: "We check property sites continuously",
                                        icon: Clock
                                    },
                                    {
                                        title: "Instant Alerts",
                                        description: "Get notified as soon as properties match",
                                        icon: Bell
                                    },
                                    {
                                        title: "Beat the Competition",
                                        description: "Be first to contact agents for viewings",
                                        icon: ChevronRight
                                    }
                                ].map((step, i) => (
                                    <div key={i} className="text-center bg-black/30 p-5 rounded-lg backdrop-blur-sm">
                                        <div
                                            className="w-12 h-12 rounded-full bg-blue-600/80 mb-3 mx-auto flex items-center justify-center">
                                            <step.icon className="h-6 w-6 text-white" aria-hidden="true"/>
                                        </div>
                                        <h3 className="text-lg font-semibold mb-2">{step.title}</h3>
                                        <p className="text-sm text-gray-200">{step.description}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                </section>


                {/* Why Use Dwellio Section */}
                <div className="py-16 bg-gray-50">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="text-center mb-12">
                            <h2 className="text-3xl font-bold text-gray-900">
                                Why Speed Matters in Today's Rental Market
                            </h2>
                        </div>
                        <div className="grid md:grid-cols-3 gap-8">
                            <div
                                className="bg-white p-8 rounded-lg shadow-sm transform hover:scale-105 transition-transform">
                                <div className="text-4xl font-bold text-blue-600 mb-2">7+ Hours</div>
                                <p className="text-gray-600">saved per week by not manually checking multiple
                                    websites</p>
                            </div>
                            <div
                                className="bg-white p-8 rounded-lg shadow-sm transform hover:scale-105 transition-transform">
                                <div className="text-4xl font-bold text-blue-600 mb-2">15 Minutes</div>
                                <p className="text-gray-600">average alert time after a property is listed</p>
                            </div>
                            <div
                                className="bg-white p-8 rounded-lg shadow-sm transform hover:scale-105 transition-transform">
                                <div className="text-4xl font-bold text-blue-600 mb-2">3x More</div>
                                <p className="text-gray-600">likely to secure viewings when you're among first to
                                    contact</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Comparison Section */}
                <div className="py-16 bg-gray-50">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="text-center mb-12">
                            <h2 className="text-3xl font-bold text-gray-900">
                                The Dwellio Advantage
                            </h2>
                            <p className="mt-4 text-xl text-gray-600">
                                See how Dwellio transforms your rental search
                            </p>
                        </div>
                        <div className="grid md:grid-cols-2 gap-8">
                            <div className="bg-white p-8 rounded-lg shadow-sm">
                                <h3 className="text-xl font-semibold mb-6 text-red-600 flex items-center"
                                    style={{marginLeft: '32px'}}>
                                    <AlertCircle className="h-6 w-6 mr-2"/>
                                    Without Dwellio
                                </h3>
                                <ul className="space-y-6">
                                    <li className="flex items-start">
                                        <X className="h-5 w-5 text-red-500 mr-3 mt-1 flex-shrink-0"/>
                                        <div>
                                            <span className="font-medium">Manual Property Search</span>
                                            <p className="text-sm text-gray-600 mt-1">Spending hours checking multiple
                                                websites daily</p>
                                        </div>
                                    </li>
                                    <li className="flex items-start">
                                        <X className="h-5 w-5 text-red-500 mr-3 mt-1 flex-shrink-0"/>
                                        <div>
                                            <span className="font-medium">Late to Know</span>
                                            <p className="text-sm text-gray-600 mt-1">Properties already let when you
                                                enquire</p>
                                        </div>
                                    </li>
                                    <li className="flex items-start">
                                        <X className="h-5 w-5 text-red-500 mr-3 mt-1 flex-shrink-0"/>
                                        <div>
                                            <span className="font-medium">Missing Opportunities</span>
                                            <p className="text-sm text-gray-600 mt-1">Perfect properties gone before you
                                                even see them</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="bg-white p-8 rounded-lg shadow-sm">
                                <h3 className="text-xl font-semibold mb-6 text-green-600 flex items-center"
                                    style={{marginLeft: '32px'}}>
                                    <Check className="h-6 w-6 mr-2"/>
                                    With Dwellio
                                </h3>
                                <ul className="space-y-6">
                                    <li className="flex items-start">
                                        <Check className="h-5 w-5 text-green-500 mr-3 mt-1 flex-shrink-0"/>
                                        <div>
                                            <span className="font-medium">Automated Alerts</span>
                                            <p className="text-sm text-gray-600 mt-1">Instant notifications for new
                                                properties matching your
                                                criteria</p>
                                        </div>
                                    </li>
                                    <li className="flex items-start">
                                        <Check className="h-5 w-5 text-green-500 mr-3 mt-1 flex-shrink-0"/>
                                        <div>
                                            <span className="font-medium">First to Know</span>
                                            <p className="text-sm text-gray-600 mt-1">Be among the first to contact
                                                agents and arrange
                                                viewings</p>
                                        </div>
                                    </li>
                                    <li className="flex items-start">
                                        <Check className="h-5 w-5 text-green-500 mr-3 mt-1 flex-shrink-0"/>
                                        <div>
                                            <span className="font-medium">Never Miss Out</span>
                                            <p className="text-sm text-gray-600 mt-1">Catch every relevant listing in
                                                your desired areas</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Enhanced Testimonials Section */}
                <div className="py-16 bg-white">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="text-center mb-12">
                            <h2 className="text-3xl font-bold text-gray-900">
                                What Our Users Say
                            </h2>
                            <p className="mt-4 text-xl text-gray-600">
                                Join thousands who found their perfect rental with Dwellio
                            </p>
                        </div>
                        <div className="grid md:grid-cols-3 gap-8">
                            {[
                                {
                                    name: "Sarah J.",
                                    location: "Manchester",
                                    role: "Young Professional",
                                    testimonial: "Found my dream flat in 2 days! The instant alerts made all the difference in this competitive market.",
                                    achievement: "Secured property within 48 hours",
                                    plan: "Pro Plan"
                                },
                                {
                                    name: "James K.",
                                    location: "London",
                                    role: "Student",
                                    testimonial: "As a student, I needed to find a property quickly before term started. Dwellio helped me secure a great house share.",
                                    achievement: "Found perfect student housing",
                                    plan: "Basic Plan"
                                },
                                {
                                    name: "Emma T.",
                                    location: "Birmingham",
                                    role: "Remote Worker",
                                    testimonial: "Being able to search in multiple areas helped me find the perfect work-from-home space within my budget.",
                                    achievement: "Searched 3 different areas",
                                    plan: "Pro Plan"
                                }
                            ].map((testimonial, index) => (
                                <div key={index}
                                     className="bg-gray-50 p-6 rounded-lg hover:shadow-md transition-shadow">
                                    <div className="flex items-center mb-4">
                                        <img src="/user.png" width={40} height={40} alt="User"
                                             className="rounded-full"/>
                                        <div className="ml-3">
                                            <h4 className="font-semibold">{testimonial.name}</h4>
                                            <p className="text-sm text-gray-600">{testimonial.location}</p>
                                        </div>
                                    </div>
                                    <p className="text-gray-600 mb-4">"{testimonial.testimonial}"</p>
                                    <div className="flex items-center justify-between text-sm">
                                        <span className="text-blue-600">{testimonial.plan}</span>
                                        <span className="bg-green-100 text-green-600 px-2 py-1 rounded-full">
                      {testimonial.achievement}
                    </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>


                {/* CTA Section */}
                <div className="bg-blue-600 py-12">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="bg-white/10 rounded-2xl p-8">
                            <div className="text-center">
                                <h2 className="text-3xl font-bold text-white mb-4">
                                    Ready to find your perfect rental?
                                </h2>
                                <p className="text-xl text-blue-100 mb-8 max-w-2xl mx-auto">
                                    Start getting alerts for new properties that match your requirements today.
                                </p>
                                <button
                                    onClick={() => navigate('/subscriptions')}
                                    className="px-8 py-3 rounded-lg bg-white text-blue-600 font-medium hover:bg-gray-100 transition-colors flex items-center justify-center mx-auto"
                                >
                                    Start 7 Day Free Trial
                                    <ArrowRight className="ml-2 h-5 w-5"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
};

export default Home;