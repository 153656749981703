import React, {useEffect, useState} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import Navbar from "./NavMenu";
import SubscriptionPrompt from "./SubscriptionPrompt";
import PreferencesTable from "./PreferencesTable";
import SubscriptionStatusCard from "./SubscriptionStatusCard";
import MatchedListingsTable from "./MatchedListingsTable";
import AddOrEditPreferenceModal from "./AddOrEditPreferenceModal";
import {
    Spinner,
    Pagination,
    OverlayTrigger,
    Tooltip,
    Button,
} from "react-bootstrap"; // removed alert
import CustomPagination from "./CustomPagination";
import SubscriptionRequiredModal from "./SubscriptionRequiredModal";
import "../custom.css";
import {
    AlertCircle,
    Bell,
    Plus,
    Settings,
    Edit3,
    Pause,
    Play,
    Trash2,
    CheckCircle2,
    Clock,
    AlertTriangle,
    ArrowRight,
    Mail
} from "lucide-react";
import EmailVerificationModal from "./Auth/EmailVerificationModal";
import Footer from "./Footer";
import AuthUtils from "./Auth/AuthUtils";

// Since you don't have the shadcn/ui components set up, let's create a simple Alert component
const Alert = ({children, className}) => (
    <div
        className={`p-4 rounded-lg bg-white shadow-lg border border-gray-200 ${className}`}
    >
        {children}
    </div>
);

const AlertTitle = ({children}) => (
    <h4 className="text-lg font-semibold mb-1">{children}</h4>
);

const AlertDescription = ({children}) => (
    <p className="text-gray-600">{children}</p>
);

const Dashboard = () => {
    const [preferences, setPreferences] = useState([]);
    const [matchedListings, setMatchedListings] = useState([]);
    const [dropdownOptions, setDropdownOptions] = useState({
        PriceRange: [],
        PropertyType: [],
        DistanceRadius: [],
        Bedrooms: [],
    });
    const [newPreference, setNewPreference] = useState({
        city: "",
        priceRangeMin: "",
        priceRangeMax: "",
        propertyType: "",
        distanceRadius: "",
        minBedrooms: "",
        maxBedrooms: "",
    });
    const [isEditing, setIsEditing] = useState(false);
    const [editingPreferenceId, setEditingPreferenceId] = useState(null);
    const [subscriptionStatus, setSubscriptionStatus] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [listingsPerPage] = useState(20);
    const [preferenceLoaded, setPreferenceLoaded] = useState(false);
    const [showSubscriptionPrompt, setShowSubscriptionPrompt] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [dashboardAccess, setDashboardAccess] = useState(true);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const indexOfLastListing = currentPage * listingsPerPage;
    const indexOfFirstListing = indexOfLastListing - listingsPerPage;
    const currentListings = matchedListings.slice(
        indexOfFirstListing,
        indexOfLastListing,
    );
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // New state for email verification
    const [showEmailVerificationModal, setShowEmailVerificationModal] = useState(false);
    const [isEmailVerified, setIsEmailVerified] = useState(true);  // Default to true to avoid flickering
    const [userEmail, setUserEmail] = useState('');
    const [verificationCheckComplete, setVerificationCheckComplete] = useState(false);

    const handleCloseModal = () => {
        setShowModal(false);
        setErrorMessage("");
    };

    const handleShowModal = () => {
        // Check if email is verified first
        if (!isEmailVerified) {
            setShowEmailVerificationModal(true);
            return;
        }

        // Add console.log for debugging
        console.log('Showing modal', {
            subscriptionStatus,
            currentPreferences: preferences.length,
            maxPreferences: subscriptionStatus?.maxPreferences
        });

        if (subscriptionStatus && preferences.length >= subscriptionStatus.maxPreferences) {
            alert("You have reached the maximum number of alerts allowed by your subscription.");
            return;
        }

        // Reset the form when showing modal
        setNewPreference({
            city: '',
            priceRangeMin: '',
            priceRangeMax: '',
            propertyType: '',
            distanceRadius: '',
            minBedrooms: '',
            maxBedrooms: ''
        });

        setIsEditing(false);
        setEditingPreferenceId(null);
        setPreferenceLoaded(false);
        setShowModal(true);
    };

    const fetchListings = async () => {
        const token = localStorage.getItem("token");
        if (!token) {
            navigate("/login");
            return;
        }

        try {
            // Fetch preferences
            const preferencesResponse = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/listings/preferences`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            );

            // For each preference, fetch its analysis
            const preferencesWithAnalysis = await Promise.all(
                preferencesResponse.data.map(async (pref) => {
                    try {
                        const analysisResponse = await axios.get(
                            `${process.env.REACT_APP_BACKEND_URL}/api/PreferenceAnalysis/${pref.id}/analysis`,
                            {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            }
                        );

                        return {
                            ...pref,
                            analysis: analysisResponse.data.analysis,
                            analysisStatus: analysisResponse.data.status,
                            // Preserve isNew flag if it exists
                            isNew: pref.isNew || false
                        };
                    } catch (error) {
                        console.error(`Error fetching analysis for preference ${pref.id}:`, error);
                        return {
                            ...pref,
                            analysis: null,
                            analysisStatus: 'error',
                            isNew: pref.isNew || false
                        };
                    }
                })
            );

            setPreferences(preferencesWithAnalysis);

            // Fetch matched listings
            const matchedListingsResponse = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/listings/matched-listings`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            );
            setMatchedListings(
                matchedListingsResponse.data.sort(
                    (a, b) => new Date(b.dateAdded) - new Date(a.dateAdded),
                ),
            );
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // Get user profile to fetch email
    const fetchUserProfile = async () => {
        const token = localStorage.getItem("token");
        if (!token) return;

        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/auth/profile`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            setUserEmail(response.data.email);
        } catch (error) {
            console.error("Error fetching user profile:", error);
        }
    };

    useEffect(() => {
        const checkVerification = async () => {
            try {
                // Check if verification status changed since login
                const serverVerified = await AuthUtils.checkAndRefreshVerificationStatus();

                // Get combined verification status (from token or local storage)
                const isVerified = AuthUtils.getLocalVerificationStatus() || serverVerified;

                setIsEmailVerified(isVerified);
                setVerificationCheckComplete(true);
            } catch (error) {
                console.error("Error checking verification status:", error);
                setVerificationCheckComplete(true);
            }
        };

        checkVerification();

        // Also set up a polling interval to check periodically, but less frequently
        const interval = setInterval(checkVerification, 60000); // Check every minute

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const checkDashboardAccess = async () => {
            const token = localStorage.getItem("token");
            if (!token) {
                navigate("/login");
                return;
            }

            setIsLoading(true);
            try {
                // First check if we already know email is verified from local storage
                const localVerificationStatus = AuthUtils.getLocalVerificationStatus();

                if (localVerificationStatus) {
                    setIsEmailVerified(true);
                } else {
                    // Check with server
                    const accessResponse = await axios.get(
                        `${process.env.REACT_APP_BACKEND_URL}/api/dashboard/access`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );

                    // Update email verification status
                    if (accessResponse.data.hasAccess === false &&
                        accessResponse.data.reason === "email_not_verified") {
                        setIsEmailVerified(false);
                    } else {
                        setIsEmailVerified(true);
                        // Store this positive result locally
                        localStorage.setItem('emailVerifiedServerSide', 'true');
                    }
                }

                // Fetch user profile to get email (needed for verification modal)
                await fetchUserProfile();

                // Always check subscription status
                const subscriptionResponse = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/api/subscription/status`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                // Check if user has an active subscription
                if (!subscriptionResponse.data || subscriptionResponse.data.status === "No Subscription") {
                    setShowSubscriptionPrompt(true);
                    setShowEmailVerificationModal(false);
                    setDashboardAccess(false);
                } else {
                    setSubscriptionStatus(subscriptionResponse.data);
                    setShowSubscriptionPrompt(false);
                    setShowEmailVerificationModal(false);
                    setDashboardAccess(true);
                    await fetchListings();
                }

            } catch (error) {
                console.error("Error checking dashboard access:", error);
                setShowSubscriptionPrompt(true);
                setShowEmailVerificationModal(false);
                setDashboardAccess(false);
            } finally {
                setIsLoading(false);
            }
        };

        // Only run this if verification check is complete to avoid race conditions
        if (verificationCheckComplete) {
            checkDashboardAccess();
        }
    }, [navigate, verificationCheckComplete]);

    useEffect(() => {
        const fetchDropdownOptions = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/api/preferences/dropdown-options`,
                );
                setDropdownOptions(response.data);
            } catch (error) {
                console.error("Error fetching dropdown options:", error);
            }
        };

        fetchDropdownOptions();
    }, []);

    useEffect(() => {
        const fetchSubscriptionStatus = async () => {
            const token = localStorage.getItem("token");
            if (!token) {
                navigate("/login");
                return;
            }

            setIsLoading(true);
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/api/subscription/status`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    },
                );

                // Check if the user has any subscription
                if (!response.data || response.data.status === "No Subscription") {
                    setShowSubscriptionPrompt(true);
                    setDashboardAccess(false);
                } else {
                    setSubscriptionStatus(response.data);
                    setShowSubscriptionPrompt(false);
                    setDashboardAccess(true);
                }
            } catch (error) {
                setShowSubscriptionPrompt(true);
                setDashboardAccess(false);
            } finally {
                setIsLoading(false);
            }
        };

        fetchSubscriptionStatus();
    }, [navigate]);

    useEffect(() => {
        fetchListings();
    }, [navigate]);

    const handleChange = (e) => {
        setNewPreference({
            ...newPreference,
            [e.target.name]: e.target.value,
        });
    };

    const validateForm = () => {
        if (
            !newPreference.city ||
            !newPreference.priceRangeMin ||
            !newPreference.priceRangeMax ||
            !newPreference.propertyType ||
            !newPreference.distanceRadius ||
            !newPreference.minBedrooms ||
            !newPreference.maxBedrooms
        ) {
            setErrorMessage("Please fill in all fields before submitting.");
            return false;
        }
        setErrorMessage("");
        return true;
    };

    const handleEditPreference = (id) => {
        // Check if email is verified first
        if (!isEmailVerified) {
            setShowEmailVerificationModal(true);
            return;
        }

        setIsEditing(true);
        setEditingPreferenceId(id);
        const preferenceToEdit = preferences.find((pref) => pref.id === id);

        if (preferenceToEdit) {
            const formatPrice = (price) => {
                return `£${price.toLocaleString("en-UK")}`;
            };

            const formatRadius = (radius) => {
                return `${radius} miles`;
            };

            const updatedPreference = {
                city: preferenceToEdit.city || "",
                priceRangeMin: formatPrice(preferenceToEdit.priceRangeMin),
                priceRangeMax: formatPrice(preferenceToEdit.priceRangeMax),
                propertyType: preferenceToEdit.propertyType || "",
                distanceRadius: formatRadius(preferenceToEdit.distanceRadius),
                minBedrooms:
                    preferenceToEdit.minBedrooms === 0
                        ? "Studio"
                        : preferenceToEdit.minBedrooms.toString(),
                maxBedrooms: preferenceToEdit.maxBedrooms.toString(),
            };

            setNewPreference(updatedPreference);
            setPreferenceLoaded(true);
            setShowModal(true);
        }
    };

    const handleDeletePreference = async (id) => {
        // Check if email is verified first
        if (!isEmailVerified) {
            setShowEmailVerificationModal(true);
            return;
        }

        const token = localStorage.getItem("token");
        setIsDeleting(true);
        try {
            await axios.delete(
                `${process.env.REACT_APP_BACKEND_URL}/api/listings/preferences/delete/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            );

            // Directly update preferences state by removing the deleted preference
            setPreferences(prevPreferences =>
                prevPreferences.filter(pref => pref.id !== id)
            );
        } catch (error) {
            console.error("Error deleting preference:", error);
        } finally {
            setIsDeleting(false);
        }
    };

    const handleUpdatePreference = async () => {
        // Check if email is verified first
        if (!isEmailVerified) {
            setShowEmailVerificationModal(true);
            return;
        }

        if (!validateForm()) return;

        const token = localStorage.getItem("token");
        const parsedPriceRangeMin = newPreference.priceRangeMin.replace(/[^\d.]/g, "");
        const parsedPriceRangeMax = newPreference.priceRangeMax.replace(/[^\d.]/g, "");
        const parsedDistanceRadius = newPreference.distanceRadius.replace(/[^\d]/g, "");
        const parsedMinBedrooms = newPreference.minBedrooms === "Studio" ? 0 : parseInt(newPreference.minBedrooms);
        const parsedMaxBedrooms = newPreference.maxBedrooms === "Studio" ? 0 : parseInt(newPreference.maxBedrooms);

        try {
            const preferenceToSubmit = {
                ...newPreference,
                priceRangeMin: parsedPriceRangeMin ? parseFloat(parsedPriceRangeMin) : null,
                priceRangeMax: parsedPriceRangeMax ? parseFloat(parsedPriceRangeMax) : null,
                distanceRadius: parsedDistanceRadius ? parseInt(parsedDistanceRadius) : null,
                minBedrooms: parsedMinBedrooms,
                maxBedrooms: parsedMaxBedrooms,
            };

            await axios.put(
                `${process.env.REACT_APP_BACKEND_URL}/api/listings/preferences/update/${editingPreferenceId}`,
                preferenceToSubmit,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            );

            // Update the specific preference while preserving others' analysis data
            setPreferences(prevPreferences =>
                prevPreferences.map(pref => {
                    if (pref.id === editingPreferenceId) {
                        return {
                            ...pref,
                            ...preferenceToSubmit,
                            analysis: null, // Reset analysis for edited preference
                            analysisStatus: 'queued',
                            isNew: true
                        };
                    }
                    return pref;
                })
            );

            setIsEditing(false);
            setEditingPreferenceId(null);
            setNewPreference({
                city: "",
                priceRangeMin: "",
                priceRangeMax: "",
                propertyType: "",
                distanceRadius: "",
                minBedrooms: "",
                maxBedrooms: "",
            });
            handleCloseModal();
        } catch (error) {
            console.error("Error updating preference:", error);
        }
    };

    const handleAddPreference = async () => {
        // Check if email is verified first
        if (!isEmailVerified) {
            setShowEmailVerificationModal(true);
            return;
        }

        // Check subscription limits
        if (!subscriptionStatus || preferences.length >= subscriptionStatus.maxPreferences) {
            alert("You have reached the maximum number of alerts allowed by your subscription.");
            return;
        }

        // Validate the form before proceeding
        if (!validateForm()) return;

        const token = localStorage.getItem("token");

        // Clean and parse form data
        const parsedPriceRangeMin = newPreference.priceRangeMin.replace(/[^\d.]/g, "");
        const parsedPriceRangeMax = newPreference.priceRangeMax.replace(/[^\d.]/g, "");
        const parsedDistanceRadius = newPreference.distanceRadius.replace(/[^\d]/g, "");
        const parsedMinBedrooms = newPreference.minBedrooms === "Studio" ? 0 : parseInt(newPreference.minBedrooms);
        const parsedMaxBedrooms = newPreference.maxBedrooms === "Studio" ? 0 : parseInt(newPreference.maxBedrooms);

        try {
            setIsAdding(true); // Set loading state before submitting

            // Create preference payload
            const preferenceToSubmit = {
                ...newPreference,
                priceRangeMin: parsedPriceRangeMin ? parseFloat(parsedPriceRangeMin) : null,
                priceRangeMax: parsedPriceRangeMax ? parseFloat(parsedPriceRangeMax) : null,
                distanceRadius: parsedDistanceRadius ? parseInt(parsedDistanceRadius) : null,
                minBedrooms: parsedMinBedrooms,
                maxBedrooms: parsedMaxBedrooms,
            };

            // Send request to add the preference
            const addResponse = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/listings/preferences/add`,
                preferenceToSubmit,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const newPreferenceId = addResponse.data.preferenceId;

            if (!newPreferenceId) {
                console.error("No preference ID returned from the server.");
                throw new Error("Failed to retrieve the newly created preference ID.");
            }

            // Fetch the updated list of preferences
            const preferencesResponse = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/listings/preferences`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            // Update the preferences list while preserving existing analysis data
            const updatedPreferences = preferencesResponse.data.map(newPref => {
                // Find existing preference to preserve its analysis data
                const existingPref = preferences.find(p => p.id === newPref.id);

                if (newPref.id === newPreferenceId) {
                    // This is the newly added preference
                    return {
                        ...newPref,
                        isNew: true,
                        analysisStatus: 'queued',
                        analysis: null
                    };
                } else if (existingPref) {
                    // Preserve existing analysis data for existing preferences
                    return {
                        ...newPref,
                        analysis: existingPref.analysis,
                        analysisStatus: existingPref.analysisStatus,
                        isNew: existingPref.isNew
                    };
                }

                return newPref;
            });

            setPreferences(updatedPreferences);

            // Start polling for analysis status of the new preference
            const pollAnalysis = async () => {
                try {
                    const analysisResponse = await axios.get(
                        `${process.env.REACT_APP_BACKEND_URL}/api/PreferenceAnalysis/${newPreferenceId}/analysis`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );

                    // Update status regardless of whether analysis is complete
                    setPreferences(prevPreferences =>
                        prevPreferences.map(pref =>
                            pref.id === newPreferenceId
                                ? {
                                    ...pref,
                                    analysis: analysisResponse.data.analysis,
                                    analysisStatus: analysisResponse.data.status,
                                    isNew: analysisResponse.data.status === 'queued'
                                }
                                : pref
                        )
                    );

                    // Only complete polling if we have analysis results
                    return analysisResponse.data.status === 'complete';
                } catch (error) {
                    console.error("Error during analysis polling:", error);
                    return true; // Stop polling on error
                }
            };

            const pollInterval = setInterval(async () => {
                try {
                    const isComplete = await pollAnalysis();
                    if (isComplete) {
                        clearInterval(pollInterval);
                    }
                } catch (error) {
                    console.error('Error polling analysis:', error);
                    // Don't clear interval on error, let it keep trying
                }
            }, 15000);

            setTimeout(() => {
                clearInterval(pollInterval);
                setPreferences(prevPreferences =>
                    prevPreferences.map(pref =>
                        pref.id === newPreferenceId
                            ? {
                                ...pref,
                                analysisStatus: 'error',
                                isNew: false
                            }
                            : pref
                    )
                );
            }, 120000);

        } catch (error) {
            console.error("Failed to add preference:", error);
        } finally {
            // Always clean up regardless of success or failure
            setIsAdding(false);
            setNewPreference({
                city: "",
                priceRangeMin: "",
                priceRangeMax: "",
                propertyType: "",
                distanceRadius: "",
                minBedrooms: "",
                maxBedrooms: "",
            });
            handleCloseModal(); // Close the modal last, after all states are reset
        }
    };
    const handleStartFreeTrial = () => {
        // Logic to handle starting the free trial
        alert(
            "Free trial started! You can now access Tier 3 features for a week.",
        );
        // Redirect or trigger any other actions as needed
    };

    const handleSubscribe = (tier) => {
        // Logic to handle the subscription process based on the selected tier
        alert(`Subscribed to ${tier} plan successfully!`);
        // You can add any additional logic for processing the subscription or redirecting the user
    };

    const handlePausePreference = async (id, isActive) => {
        // Check if email is verified first
        if (!isEmailVerified) {
            setShowEmailVerificationModal(true);
            return;
        }

        const token = localStorage.getItem("token");
        if (!token) {
            alert("User is not authenticated. Please log in.");
            return;
        }

        try {
            const updateDto = {
                id: id,
                isActive: isActive,
            };

            await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/preferences/update-status`,
                updateDto,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                },
            );

            // Update the specific preference while preserving analysis data
            setPreferences(prevPreferences =>
                prevPreferences.map((pref) =>
                    pref.id === id
                        ? {
                            ...pref,
                            isActive: isActive,
                            analysis: pref.analysis, // Preserve analysis data
                            analysisStatus: pref.analysisStatus
                        }
                        : pref
                )
            );
        } catch (error) {
            console.error("Error updating preference status:", error);
            alert("Failed to update preference status. Please try again.");
        }
    };
    const handleDismissAnalysis = async (preferenceId) => {
        try {
            // Update the local state immediately for UI responsiveness
            const updatedPreferences = preferences.map(pref =>
                pref.id === preferenceId
                    ? {
                        ...pref,
                        analysis: pref.analysis ? {
                            ...pref.analysis,
                            isActive: false
                        } : null
                    }
                    : pref
            );
            setPreferences(updatedPreferences);
        } catch (error) {
            console.error('Error dismissing analysis:', error);
        }
    };

    useEffect(() => {
        const pollQueuedAnalyses = async () => {
            const queuedPreferences = preferences.filter(
                pref => pref.isNew && (!pref.analysisStatus || pref.analysisStatus === 'queued')
            );

            if (queuedPreferences.length > 0) {
                const timer = setTimeout(() => {
                    fetchListings();
                }, 15000);

                return () => clearTimeout(timer);
            }
        };

        pollQueuedAnalyses();
    }, [preferences]);

    return (
        <div className="min-h-screen bg-gray-50">
            <Navbar/>

            {isLoading ? (
                <div className="flex items-center justify-center min-h-screen">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
                </div>
            ) : !dashboardAccess ? (
                <>
                    <EmailVerificationModal
                        show={showEmailVerificationModal}
                        onClose={() => setShowEmailVerificationModal(false)}
                        email={userEmail}
                    />
                    <SubscriptionRequiredModal
                        show={showSubscriptionPrompt}
                        onClose={() => setShowSubscriptionPrompt(false)}
                    />
                </>
            ) : (
                <div className="min-h-screen bg-gray-50">

                    {isLoading ? (
                        <div className="flex items-center justify-center min-h-screen">
                            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
                        </div>
                    ) : !dashboardAccess ? (
                        <SubscriptionRequiredModal
                            onClose={() => setShowSubscriptionPrompt(false)}
                        />
                    ) : (
                        <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
                            <div className="flex justify-between items-center mb-6">
                                <h1 className="text-2xl font-bold text-gray-900">
                                    Dashboard
                                </h1>
                                <button
                                    onClick={handleShowModal}
                                    disabled={
                                        preferences.length >=
                                        subscriptionStatus?.maxPreferences
                                    }
                                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
                                >
                                    <Plus className="h-4 w-4 mr-2"/>
                                    Add New Alert
                                </button>
                            </div>

                            {/* Email Verification Banner */}
                            {!isEmailVerified && (
                                <div className="mb-6 bg-blue-50 border border-blue-200 rounded-lg p-4">
                                    <div className="flex items-start space-x-3">
                                        <Mail className="h-5 w-5 text-blue-500 flex-shrink-0 mt-0.5"/>
                                        <div className="flex-1">
                                            <h3 className="text-lg font-semibold text-gray-900">
                                                Verify Your Email
                                            </h3>
                                            <p className="text-gray-600 mt-1 mb-3">
                                                Please verify your email address to create alerts and receive notifications.
                                            </p>
                                            <button
                                                onClick={() => setShowEmailVerificationModal(true)}
                                                className="inline-flex items-center px-3 py-1.5 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                            >
                                                Verify Email
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {subscriptionStatus && (
                                <SubscriptionStatusCard
                                    status={subscriptionStatus.status}
                                    expiresIn={subscriptionStatus.expiresIn}
                                    maxPreferences={subscriptionStatus.maxPreferences}
                                    notificationDelay={
                                        subscriptionStatus.notificationDelayMinutes
                                    }
                                    preferencesCount={preferences.length}
                                />
                            )}

                            {preferences.length >= subscriptionStatus?.maxPreferences && (
                                <div
                                    className="mb-6 bg-gradient-to-r from-blue-50 to-indigo-50 border border-blue-100 rounded-lg p-6">
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center space-x-4">
                                            <div className="flex-shrink-0">
                                                <AlertCircle className="h-6 w-6 text-blue-500"/>
                                            </div>
                                            <div>
                                                <h3 className="text-sm font-medium text-blue-800">
                                                    Alert Limit Reached
                                                </h3>
                                                <p className="text-sm text-blue-600 mt-1">
                                                    You've reached the maximum number of alerts for your current
                                                    plan.
                                                </p>
                                            </div>
                                        </div>
                                        <a
                                            href="/subscriptions"
                                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
                                        >
                                            Upgrade Now
                                            <ArrowRight className="ml-2 h-4 w-4"/>
                                        </a>
                                    </div>
                                </div>
                            )}

                            <PreferencesTable
                                preferences={preferences}
                                onEdit={handleEditPreference}
                                onDelete={handleDeletePreference}
                                onPause={handlePausePreference}
                                onAddPreference={handleShowModal}
                                onDismissAnalysis={handleDismissAnalysis}
                            />

                            <AddOrEditPreferenceModal
                                showModal={showModal}
                                handleCloseModal={handleCloseModal}
                                handleChange={handleChange}
                                handleAddPreference={handleAddPreference}
                                handleUpdatePreference={handleUpdatePreference}
                                newPreference={newPreference}
                                dropdownOptions={dropdownOptions}
                                isEditing={isEditing}
                                preferenceLoaded={preferenceLoaded}
                                isAdding={isAdding}
                                userTier={subscriptionStatus?.status}
                            />

                            {subscriptionStatus && !isLoading && (
                                <MatchedListingsTable
                                    listings={matchedListings}
                                    showModal={showModal}
                                    handleCloseModal={handleCloseModal}
                                    handleChange={handleChange}
                                    handleAddPreference={handleAddPreference}
                                    handleUpdatePreference={handleUpdatePreference}
                                    newPreference={newPreference}
                                    dropdownOptions={dropdownOptions}
                                    isEditing={isEditing}
                                    preferenceLoaded={preferenceLoaded}
                                    isAdding={isAdding}
                                    userTier={subscriptionStatus.status}
                                    preferences={preferences}
                                    onAddPreference={handleShowModal}
                                />
                            )}

                            {/* Email Verification Modal */}
                            <EmailVerificationModal
                                show={showEmailVerificationModal}
                                onClose={() => setShowEmailVerificationModal(false)}
                                email={userEmail}
                            />
                        </main>
                    )}
                </div>
            )}
            <Footer/>
        </div>
    );
};
export default Dashboard;