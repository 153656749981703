import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {
    Menu,
    X,
    User,
    Home,
    LayoutDashboard,
    CreditCard,
    Info,
    MessageCircle,
    HelpCircle,
    LogOut,
    LogIn,
    UserPlus
} from 'lucide-react';
import {useAuth} from './Context/AuthProvider';
import {authService} from './Services/AuthService';

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const {isAuthenticated, handleLogout} = useAuth();
    const isHomePage = window.location.pathname === '/';
    const navigate = useNavigate();

    const navLinks = [
        {path: '/', name: 'Home'},
        {path: '/subscriptions', name: 'Subscription'},
        {path: '/about-us', name: 'About Us'},
        {path: '/contact', name: 'Contact Us'},
        {path: '/how-it-works', name: 'How It Works'},
    ];

    if (isAuthenticated) {
        navLinks.push(
            {path: '/dashboard', name: 'Dashboard'},
            {path: '/profile', name: 'Profile'}
        );
    } else {
        navLinks.push({path: '/login', name: 'Login'});
    }

    const onLogout = async () => {
        try {
            await authService.logout();
            handleLogout();
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    const MobileMenu = ({isOpen, setIsOpen, isAuthenticated}) => (
        <div className={`dwellio-mobile-menu ${isOpen ? 'block' : 'hidden'}`}>
            {/* User Section */}
            <div className="dwellio-mobile-menu__section bg-gray-50">
                <Link
                    to={isAuthenticated ? '/dashboard' : '/login'}
                    className="dwellio-mobile-menu__item"
                    onClick={() => setIsOpen(false)}  // Close menu after click
                >
                    <div className="dwellio-mobile-menu__item">
                        <User className="h-5 w-5 mr-3 text-gray-500"/>
                        {isAuthenticated ? 'My Dashboard' : 'Sign in or create account'}
                    </div>
                </Link>
            </div>

            {/* Main Navigation */}
            <div className="dwellio-mobile-menu__section">
                <Link to="/" className="dwellio-mobile-menu__item">
                    <Home className="h-5 w-5 mr-3 text-blue-600"/>
                    Home
                </Link>
                <Link to="/dashboard" className="dwellio-mobile-menu__item">
                    <LayoutDashboard className="h-5 w-5 mr-3 text-blue-600"/>
                    Dashboard
                </Link>
                <Link to="/profile" className="dwellio-mobile-menu__item">
                    <User className="h-5 w-5 mr-3 text-blue-600"/>
                    Profile
                </Link>
            </div>

            {/* Subscription Section */}
            <div className="dwellio-mobile-menu__section">
                <div className="dwellio-mobile-menu__title">Subscription</div>
                <Link to="/subscriptions" className="dwellio-mobile-menu__item">
                    <CreditCard className="h-5 w-5 mr-3 text-blue-600"/>
                    Plans & Pricing
                </Link>
            </div>

            {/* Help & Support */}
            <div className="dwellio-mobile-menu__section">
                <div className="dwellio-mobile-menu__title">Help & Support</div>
                <Link to="/about-us" className="dwellio-mobile-menu__item">
                    <Info className="h-5 w-5 mr-3 text-blue-600"/>
                    About Us
                </Link>
                <Link to="/contact" className="dwellio-mobile-menu__item">
                    <MessageCircle className="h-5 w-5 mr-3 text-blue-600"/>
                    Contact Us
                </Link>
                <Link to="/how-it-works" className="dwellio-mobile-menu__item">
                    <HelpCircle className="h-5 w-5 mr-3 text-blue-600"/>
                    How It Works
                </Link>
            </div>

            {/* Auth Actions */}
            {isAuthenticated && (
                <div className="dwellio-mobile-menu__section">
                    <button
                        onClick={() => {
                            onLogout();
                            setIsOpen(false);
                        }}
                        className="dwellio-mobile-menu__item text-red-600"
                    >
                        <LogOut className="h-5 w-5 mr-3"/>
                        Logout
                    </button>
                </div>
            )}
        </div>
    );

    return (
        <nav className={`w-full z-20 ${isHomePage ? 'absolute bg-transparent' : 'bg-white border-b border-gray-100'}`}>
            {/* Mobile Navigation */}
            <div className="dwellio-mobile-nav md:hidden">
                {/* Left Section */}
                <div className="dwellio-mobile-nav__left">
                    <button
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        className={`p-4 ${isHomePage ? 'text-white' : 'text-gray-600'}`}
                    >
                        {isMenuOpen ? <X className="h-6 w-6"/> : <Menu className="h-6 w-6"/>}
                    </button>
                </div>

                {/* Center Section */}
                <div className="dwellio-mobile-nav__center">
                    <button onClick={() => navigate("/")} className="flex items-center space-x-2">
                        <span className={`text-xl font-bold ${isHomePage ? "text-white" : "text-gray-900"}`}>
                          Dwellio
                        </span>
                        <img src="/Dwellio.svg" alt="Dwellio" className="h-8 w-8 md:h-10 md:w-10"/>
                    </button>
                </div>

                {/* Right Section */}
                <div className="dwellio-mobile-nav__right">
                    <button onClick={() => navigate('/dashboard')}
                            className={`p-4 ${isHomePage ? 'text-white' : 'text-gray-600'}`}>
                        <User className="h-6 w-6"/>
                    </button>
                </div>
            </div>

            {/* Desktop Navigation */}
            <div className="hidden md:block max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between h-16 items-center dwellio-nav">
                    <Link to="/" className="flex items-center">
                    <span className={`text-xl font-bold ${isHomePage ? 'text-white' : 'text-gray-900'}`}>
                        Dwellio
                    </span>
                    </Link>

                    <div className="hidden md:flex items-center space-x-6">
                        {navLinks.map(link => (
                            <Link
                                key={link.path}
                                to={link.path}
                                className={`px-3 py-2 text-sm font-medium no-underline
                                ${isHomePage
                                    ? 'text-white hover:text-blue-200'
                                    : 'text-gray-600 hover:text-gray-900'}`}
                            >
                                {link.name}
                            </Link>
                        ))}

                        {isAuthenticated ? (
                            <button
                                onClick={onLogout}
                                className="px-4 py-2 rounded-lg bg-blue-600 hover:bg-blue-700 text-white text-sm font-medium"
                            >
                                Logout
                            </button>
                        ) : (
                            <Link
                                to="/register"
                                className="px-4 py-2 rounded-lg bg-blue-600 hover:bg-blue-700 text-white text-sm font-medium no-underline"
                            >
                                Register
                            </Link>
                        )}
                    </div>
                </div>
            </div>

            {/* Mobile Menu */}
            <MobileMenu
                isOpen={isMenuOpen}
                setIsOpen={setIsMenuOpen}
                navLinks={navLinks}
                isAuthenticated={isAuthenticated}
                isHomePage={isHomePage}
            />
        </nav>
    );
};

export default Navbar;