import React from 'react';
import { ChartBar, Target, Sparkles, Lightbulb, ArrowUpRight } from 'lucide-react';

const AnalysisSection = ({ preference, onDismiss }) => {
    const [isExpanded, setIsExpanded] = React.useState(true);
    const [isDismissed, setIsDismissed] = React.useState(false);

    // Don't render if analysis doesn't exist, is already dismissed, or is not active
    if (!preference.analysis || isDismissed || !preference.analysis.isActive) return null;

    const { totalListings, avgPerDay, suggestions = [], riskLevel } = preference.analysis;

    const handleDismiss = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/PreferenceAnalysis/${preference.id}/analysis/dismiss`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.ok) {
                setIsDismissed(true);
                if (onDismiss) {
                    onDismiss(preference.id);
                }
            }
        } catch (error) {
            console.error('Error dismissing analysis:', error);
        }
    };

    const statusConfig = {
        high: {
            icon: <Target className="w-5 h-5 text-red-500" />,
            title: 'Low Match Rate',
            description: 'You might receive fewer notifications than expected.',
            bg: 'bg-red-50',
            border: 'border-red-100',
            text: 'text-red-700',
            iconBg: 'bg-red-100'
        },
        medium: {
            icon: <ChartBar className="w-5 h-5 text-yellow-500" />,
            title: 'Moderate Match Rate',
            description: 'Consider adjusting criteria for more options.',
            bg: 'bg-yellow-50',
            border: 'border-yellow-100',
            text: 'text-yellow-700',
            iconBg: 'bg-yellow-100'
        },
        low: {
            icon: <Sparkles className="w-5 h-5 text-green-500" />,
            title: 'Great Match Rate',
            description: 'Your criteria are well optimized.',
            bg: 'bg-green-50',
            border: 'border-green-100',
            text: 'text-green-700',
            iconBg: 'bg-green-100'
        }
    };

    const config = statusConfig[riskLevel] || statusConfig.medium;

    return (
        <div className={`rounded-lg border ${config.border} ${config.bg} overflow-hidden transition-all duration-200 mb-4`}>
            <div className="p-4">
                <div className="flex items-start space-x-4">
                    <div className={`${config.iconBg} p-2 rounded-lg`}>
                        {config.icon}
                    </div>

                    <div className="flex-1 min-w-0">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center space-x-3">
                                <h4 className={`text-sm font-medium ${config.text}`}>
                                    {config.title}
                                </h4>
                                {riskLevel !== 'low' && (
                                    <button
                                        onClick={() => setIsExpanded(!isExpanded)}
                                        className={`${config.text} hover:opacity-75 text-sm font-medium`}
                                    >
                                        {isExpanded ? 'Show Less' : 'Show More'}
                                    </button>
                                )}
                            </div>
                            <button
                                onClick={handleDismiss}
                                className={`${config.text} hover:opacity-75 text-sm font-medium`}
                            >
                                Dismiss
                            </button>
                        </div>

                        <p className={`mt-1 text-sm ${config.text}`}>
                            Based on the last 7 days ({totalListings} listings),
                            you can expect around <strong>{avgPerDay} properties per day</strong>.
                            {' '}{config.description}
                        </p>

                        {isExpanded && suggestions.length > 0 && (
                            <div className="mt-4 space-y-3">
                                <div className="flex items-center space-x-2">
                                    <Lightbulb className={`w-4 h-4 ${config.text}`} />
                                    <span className={`text-sm font-medium ${config.text}`}>
                                        Suggestions to improve matches:
                                    </span>
                                </div>

                                <div className="grid gap-2">
                                    {suggestions.map((suggestion, index) => (
                                        <div
                                            key={index}
                                            className={`flex items-center space-x-2 p-2 rounded-lg bg-white/50 ${config.border}`}
                                        >
                                            <ArrowUpRight className={`w-4 h-4 ${config.text}`} />
                                            <span className={`text-sm ${config.text}`}>{suggestion}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AnalysisSection;