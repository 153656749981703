import React, { useState } from 'react';
import axios from 'axios';
import Navbar from '../NavMenu';
import '../../custom.css';
import { Helmet } from 'react-helmet-async';
import { useAuth } from "../Context/AuthProvider";
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import {authService} from "../Services/AuthService";
import { useNavigate, useLocation , Link} from 'react-router-dom';
import Footer from '../Footer';

const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [errors, setErrors] = useState([]);
    const [emailError, setEmailError] = useState(null);
    const [passwordErrors, setPasswordErrors] = useState([]);
    const [passwordMatchError, setPasswordMatchError] = useState(null);
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const navigate = useNavigate();
    const { setIsAuthenticated, refreshAuthState } = useAuth();
    const [error, setError] = useState(null);
    const [firstNameError, setFirstNameError] = useState(null);
    const location = useLocation();
    const redirectTo = location.state?.redirectTo;
    const pendingTier = location.state?.tier;
    // Regex for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Function to validate password strength
    const validatePassword = (password) => {
        const requirements = [];
        if (password.length < 8) requirements.push("Password must be at least 8 characters long.");
        if (!/[A-Z]/.test(password)) requirements.push("Password must include at least one uppercase letter.");
        if (!/[0-9]/.test(password)) requirements.push("Password must include at least one number.");
        return requirements;
    };

    const handleEmailChange = (e) => {
        const emailValue = e.target.value;
        setEmail(emailValue);

        // Live validation for email
        if (!emailRegex.test(emailValue)) {
            setEmailError("Please enter a valid email address.");
        } else {
            setEmailError(null);
        }
    };

    const handlePasswordChange = (e) => {
        const passwordValue = e.target.value;
        setPassword(passwordValue);

        // Live validation for password
        const errors = validatePassword(passwordValue);
        setPasswordErrors(errors);
    };

    const handleConfirmPasswordChange = (e) => {
        const confirmPasswordValue = e.target.value;
        setConfirmPassword(confirmPasswordValue);

        // Live validation for password match
        if (confirmPasswordValue !== password) {
            setPasswordMatchError("Passwords do not match.");
        } else {
            setPasswordMatchError(null);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!acceptedTerms) {
            setErrors(["You must accept the Terms and Conditions to create an account."]);
            return;
        }

        if (!firstName.trim()) {
            setFirstNameError("First name is required.");
            return;
        }

        try {
            console.log("Starting registration...");
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/Auth/register`, {
                email,
                password,
                firstName,
            });

            console.log("Registration response:", response.data);

            if (response.data && response.data.token) {
                console.log("Setting token...");
                // First store the token but don't set auth state yet
                localStorage.setItem('token', response.data.token);
                sessionStorage.setItem('registeredEmail', email);

                const pendingSubscription = localStorage.getItem('pendingSubscription');
                console.log("Pending subscription:", pendingSubscription);

                if (pendingSubscription) {
                    // For subscription flow, we need auth immediately
                    refreshAuthState();
                    axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;

                    console.log("Navigating to checkout...");
                    navigate('/subscription-checkout', {
                        state: {
                            tier: pendingSubscription,
                            email: email
                        }
                    });
                    localStorage.removeItem('pendingSubscription');
                    return;
                }

                // For regular registration, navigate first, then set auth
                navigate('/registration-success', {
                    state: { registered: true }
                });

                // Set auth state after navigation
                setTimeout(() => {
                    refreshAuthState();
                    axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
                }, 100);
            }

        } catch (err) {
            // Handle server errors
            if (err.response && err.response.data) {
                const apiErrors = err.response.data.errors || err.response.data;
                if (Array.isArray(apiErrors)) {
                    const errorMessages = apiErrors.map(error => error.description);
                    setErrors(errorMessages);
                } else if (typeof apiErrors === 'object') {
                    setErrors([apiErrors.description || "Registration failed."]);
                }
            } else {
                setErrors(["An unexpected error occurred. Please try again."]);
            }
        }
    };

    const handleGoogleSuccess = async (credentialResponse) => {
        try {
            const decodeToken = jwtDecode(credentialResponse.credential);
            const firtName = decodeToken.given_name;


            const response = await authService.googleSignIn(credentialResponse.credential);
            localStorage.setItem('token', response.token);
            localStorage.setItem('userEmail', jwtDecode(credentialResponse.credential).email);
            localStorage.setItem('firstName', firtName);
            setIsAuthenticated(true);

            const pendingSubscription = localStorage.getItem('pendingSubscription');
            if (redirectTo) {
                navigate(redirectTo, { state: { tier: pendingTier } });
            } else if (pendingSubscription) {
                navigate('/subscription-checkout', {
                    state: { tier: pendingSubscription }
                });
                localStorage.removeItem('pendingSubscription');
            } else {
                navigate('/dashboard');
            }
        } catch (err) {
            setError("Failed to sign up with Google. Please try again.");
        }
    };

    const handleFirstNameChange = (e) => {
        const firstNameValue = e.target.value;
        setFirstName(firstNameValue);

        // Basic validation
        if (!firstNameValue.trim()) {
            setFirstNameError("First name is required.");
        } else {
            setFirstNameError(null);
        }
    };

    return (
        <>
            <Helmet>
                <title>Create Your Dwellio Account - Start Getting Property Alerts</title>
                <meta name="description" content="Sign up for Dwellio to start receiving instant property alerts. Create your account and be first to know about new rental properties in your area." />
                <link rel="canonical" href="https://dwellio.co.uk/register" />
                <meta property="og:title" content="Create Your Dwellio Account - Start Getting Property Alerts" />
                <meta property="og:description" content="Sign up for Dwellio to start receiving instant property alerts." />
                <meta property="og:url" content="https://dwellio.co.uk/register" />
                <meta property="og:type" content="website" />
            </Helmet>
            <Navbar />
            <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
                <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                    {/* Header Section */}
                    <div className="text-center max-w-3xl mx-auto mb-12">
                        <h1 className="text-4xl font-bold text-gray-900 mb-4">
                            Create Your Account
                        </h1>
                        <p className="text-xl text-gray-600">
                            Join us to start receiving personalized property alerts
                        </p>
                    </div>

                    {/* Registration Form */}
                    <div className="max-w-md mx-auto">
                        <div className="rounded-2xl border border-gray-200 bg-white shadow-sm p-8">
                            <form onSubmit={handleSubmit} className="space-y-6">
                                <div className="space-y-2">
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                        Email address
                                    </label>
                                    <input
                                        id="email"
                                        type="email"
                                        value={email}
                                        onChange={handleEmailChange}
                                        required
                                        className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                        placeholder="Enter your email"
                                    />
                                    {emailError && (
                                        <p className="text-sm text-red-600 mt-1">{emailError}</p>
                                    )}
                                </div>

                                <div className="space-y-2">
                                    <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
                                        First Name
                                    </label>
                                    <input
                                        id="firstName"
                                        type="text"
                                        value={firstName}
                                        onChange={handleFirstNameChange}
                                        required
                                        className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                        placeholder="Enter your first name"
                                    />
                                    {firstNameError && (
                                        <p className="text-sm text-red-600 mt-1">{firstNameError}</p>
                                    )}
                                </div>

                                <div className="space-y-2">
                                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                        Password
                                    </label>
                                    <input
                                        id="password"
                                        type="password"
                                        value={password}
                                        onChange={handlePasswordChange}
                                        required
                                        className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                        placeholder="Create a password"
                                    />
                                    {passwordErrors.length > 0 && (
                                        <ul className="mt-1 text-sm text-red-600 list-disc list-inside">
                                            {passwordErrors.map((error, index) => (
                                                <li key={index}>{error}</li>
                                            ))}
                                        </ul>
                                    )}
                                </div>

                                <div className="space-y-2">
                                    <label htmlFor="confirmPassword"
                                           className="block text-sm font-medium text-gray-700">
                                        Confirm Password
                                    </label>
                                    <input
                                        id="confirmPassword"
                                        type="password"
                                        value={confirmPassword}
                                        onChange={handleConfirmPasswordChange}
                                        required
                                        className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                        placeholder="Confirm your password"
                                    />
                                    {passwordMatchError && (
                                        <p className="text-sm text-red-600 mt-1">{passwordMatchError}</p>
                                    )}
                                </div>

                                {errors.length > 0 && (
                                    <div className="p-4 rounded-lg bg-red-50">
                                        <ul className="list-disc list-inside text-red-600">
                                            {errors.map((error, index) => (
                                                <li key={index}>{error}</li>
                                            ))}
                                        </ul>
                                    </div>
                                )}

                                <div className="space-y-2">
                                    <div className="flex items-start">
                                        <div className="flex items-center h-5">
                                            <input
                                                id="terms"
                                                type="checkbox"
                                                checked={acceptedTerms}
                                                onChange={(e) => setAcceptedTerms(e.target.checked)}
                                                className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                            />
                                        </div>
                                        <div className="ml-3">
                                            <label htmlFor="terms" className="text-sm text-gray-600">
                                                I agree to the{' '}
                                                <Link
                                                    to="/terms"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="text-blue-600 hover:text-blue-700 font-medium"
                                                >
                                                    Terms and Conditions
                                                </Link>
                                                {' '}and{' '}
                                                <Link
                                                    to="/privacy"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="text-blue-600 hover:text-blue-700 font-medium"
                                                >
                                                    Privacy Policy
                                                </Link>
                                            </label>
                                        </div>
                                    </div>
                                    {!acceptedTerms && errors.includes("You must accept the Terms and Conditions to create an account.") && (
                                        <p className="text-sm text-red-600">You must accept the Terms and Conditions to
                                            continue.</p>
                                    )}
                                </div>

                                <button
                                    type="submit"
                                    className="w-full py-3 px-6 rounded-lg bg-blue-600 hover:bg-blue-700 text-white font-medium transition-colors"
                                >
                                    Create Account
                                </button>
                            </form>

                            <div className="mt-6">
                                <div className="relative">
                                    <div className="absolute inset-0 flex items-center">
                                        <div className="w-full border-t border-gray-300"/>
                                    </div>
                                    <div className="relative flex justify-center text-sm">
                                        <span className="px-2 bg-white text-gray-500">Or sign up with</span>
                                    </div>
                                </div>

                                <div className="mt-6 flex justify-center">
                                    <div className="w-full max-w-[320px] flex justify-center">
                                        <GoogleLogin
                                            onSuccess={handleGoogleSuccess}
                                            onError={() => {
                                                setError("Failed to sign up with Google. Please try again.");
                                            }}
                                            useOneTap
                                        />
                                    </div>
                                </div>
                            </div>


                            <div className="mt-6 text-center">
                                <p className="text-gray-600">
                                    Already have an account?{' '}
                                    <Link to="/login" className="text-blue-600 hover:text-blue-700 font-medium">
                                        Sign in
                                    </Link>
                                </p>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Register;
