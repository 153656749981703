import React, {useState, useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
import axios from 'axios';
import {
    AlertCircle,
    Loader2,
    Clock,
    MapPin,
    Star,
    Bell,
    Users,
    Check,
    ArrowRight,
    ChevronRight,
    X,
    Lightbulb,
    Target,
    ChartBar,
    Sparkles
} from 'lucide-react';
import Navbar from './NavMenu';
import Footer from './Footer';
import { loadStripe } from "@stripe/stripe-js";
import SubscriptionSelector from "./SubscriptionSelection";


const PropertyAnalysisPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [analysis, setAnalysis] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [progressValue, setProgressValue] = useState(0);
    const [selectedPlan, setSelectedPlan] = useState('pro'); // Default to pro plan
    const [currentTip, setCurrentTip] = useState(0);
    const [showAuthModal, setShowAuthModal] = useState(false);
    const [selectedTier, setSelectedTier] = useState(null);
    const promiseKey = process.env.REACT_APP_STRIPE_PROMISE_KEY;
    const stripePromise = loadStripe(promiseKey);
    const [showSuggestionBanner, setShowSuggestionBanner] = useState(true);
    const [isSuggestionsModalOpen, setIsSuggestionsModalOpen] = useState(false);

    const tips = [
        "Properties with gardens are up to 20% more in demand",
        "The average time to find a rental in this area is 2-3 weeks",
        "Listings posted on Mondays get 15% more views",
        "Properties with professional photos rent 3x faster"
    ];

    const testimonials = [
        {
            text: "Found my dream flat in 2 days with Pro alerts. Being first to contact the agent made all the difference!",
            name: "Sarah J.",
            role: "Young Professional",
            location: "London"
        },
        {
            text: "The 15-min alerts helped me secure a viewing before anyone else. Already moved into my new home!",
            name: "Mark D.",
            role: "Remote Worker",
            location: "Manchester"
        }
    ];

    const plans = {
        free: {
            name: "Free",
            price: "0",
            features: [
                {text: "24h notification delay", icon: Clock},
                {text: "1 search area", icon: MapPin},
                {text: "5 listings per day", icon: Bell},
                {text: "Basic listing details", icon: Users}
            ],
            buttonText: "Start With Free Plan",
            buttonAction: () => {
            },
            recommended: false,
            noCC: true
        },
        basic: {
            name: "Basic",
            price: "6.99",
            features: [
                {text: "3 email alerts per day", icon: Clock},
                {text: "3 search areas", icon: MapPin},
                {text: "20 listings per day", icon: Bell},
                {text: "Quick-view property details", icon: Users}
            ],
            buttonText: "Subscribe Now",
            buttonAction: () => navigate("/subscriptions?plan=basic"),
            recommended: false
        },
        pro: {
            name: "Pro",
            price: "12.99",
            features: [
                {text: "15min alerts", icon: Clock},
                {text: "10 search areas", icon: MapPin},
                {text: "Unlimited alerts", icon: Bell},
                {text: "Priority Access", icon: Star}
            ],
            trial: true,
            buttonText: "Start Free Trial",
            buttonAction: () => navigate("/subscriptions?plan=pro"),
            recommended: true
        }
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const id = params.get("id");

        if (!id) {
            setError("No preference ID provided");
            setLoading(false);
            return;
        }

        const progressInterval = setInterval(() => {
            setProgressValue(prev => (prev < 95 ? prev + 1 : prev));
        }, 300);

        const tipInterval = setInterval(() => {
            setCurrentTip(prev => (prev + 1) % tips.length);
        }, 5000);

        fetchAnalysis(id);

        return () => {
            clearInterval(progressInterval);
            clearInterval(tipInterval);
        };
    }, [location]);

    useEffect(() => {
        const timer = setTimeout(() => setShowSuggestionBanner(false), 200000); // 10 seconds
        return () => clearTimeout(timer);
    }, []);

    const fetchAnalysis = async (id) => {
        try {
            // Start polling
            pollAnalysis(id);
        } catch (error) {
            console.error("Error fetching analysis:", error);
            setError("Error starting analysis");
            setLoading(false);
        }
    };

    const pollAnalysis = async (id) => {
        let pollInterval;
        const maxPollTime = 5 * 60 * 1000; // 5 minutes in milliseconds
        let startTime = Date.now();

        const poll = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/api/PreferenceAnalysis/${id}/analysis`
                );

                if (response.data?.analysis) {
                    setAnalysis(response.data.analysis);
                    setProgressValue(100);
                    setLoading(false);
                    if (pollInterval) clearInterval(pollInterval);
                    return true;
                }

                if (response.data?.status === 'failed') {
                    setError("Analysis failed. Please try again.");
                    setLoading(false);
                    if (pollInterval) clearInterval(pollInterval);
                    return true;
                }

                return false;
            } catch (error) {
                // If we get a 404, it means the preference has expired
                if (error.response?.status === 404) {
                    setError("Analysis has expired. Please try again.");
                    setLoading(false);
                    if (pollInterval) clearInterval(pollInterval);
                    return true;
                }

                console.error("Error polling analysis:", error);
                return false;
            }
        };

        // Initial poll
        const isComplete = await poll();
        if (isComplete) return;

        // Continue polling every 15 seconds
        pollInterval = setInterval(async () => {
            const isComplete = await poll();
            if (isComplete) {
                clearInterval(pollInterval);
                return;
            }

            // Check if we've exceeded the maximum polling time
            if (Date.now() - startTime > maxPollTime) {
                setLoading(false);
                setError("Analysis took too long. Please try again later.");
                if (pollInterval) clearInterval(pollInterval);
            }
        }, 15000);
    };
    const renderPlanCard = (planKey) => {
        const plan = plans[planKey];
        const isSelected = selectedPlan === planKey;

        return (
            <div className={`p-6 relative transition-all h-full flex flex-col bg-white rounded-xl 
            ${plan.recommended ? 'border border-blue-200' : 'border border-gray-200'} 
            ${isSelected ? 'ring-2 ring-blue-500' : ''}`}
            >
                {/* Plan name and description */}
                <div className="mb-6">
                    {/* Place badge inside the card at the top */}
                    {plan.recommended && (
                        <div className="flex justify-center mb-2">
                        <span className="bg-blue-500 text-white px-3 py-1 rounded-full text-xs font-medium flex items-center">
                            <Star className="h-3 w-3 mr-1" />
                            Most Popular
                        </span>
                        </div>
                    )}

                    <h3 className={`text-xl font-bold ${plan.recommended ? 'text-blue-600' : 'text-gray-900'}`}>
                        {plan.name}
                    </h3>
                    <p className="mt-1 text-gray-600 text-sm">
                        {planKey === 'free'
                            ? 'Test drive our rental alerts'
                            : planKey === 'basic'
                                ? 'For active rental searchers'
                                : 'For urgent rental needs'}
                    </p>
                    <div className="mt-3">
                        <span className="text-3xl font-bold text-gray-900">£{plan.price}</span>
                        <span className="text-sm font-normal text-gray-600">/month</span>
                    </div>

                    {/* Trial info for Pro plan */}
                    {plan.trial && (
                        <div className="mt-3 space-y-1">
                            <div className="bg-blue-50 rounded-lg p-2 text-blue-700 text-sm font-medium flex items-center justify-center">
                                <Star className="h-4 w-4 mr-1 text-blue-500" />
                                7-Day Free Trial
                            </div>
                            <p className="text-xs text-gray-600 text-center">
                                Cancel anytime • No commitments
                            </p>
                        </div>
                    )}
                </div>

                {/* Feature list */}
                <ul className="space-y-3 mb-6 flex-grow">
                    {plan.features.map((feature, index) => (
                        <li key={index} className="flex items-start">
                            <Check className={`h-5 w-5 mr-2.5 flex-shrink-0 ${
                                plan.recommended
                                    ? 'text-blue-500'
                                    : 'text-green-500'
                            }`} />
                            <span className="text-sm text-gray-600">{feature.text}</span>
                        </li>
                    ))}
                </ul>

                {/* CTA Button */}
                <button
                    onClick={() => handleSubscriptionClick(planKey)}
                    className={`w-full py-3 px-4 rounded-lg font-medium transition-colors ${
                        plan.recommended
                            ? 'bg-blue-600 hover:bg-blue-700 text-white'
                            : planKey === 'free'
                                ? 'border border-gray-300 text-gray-700 hover:bg-gray-50'
                                : 'bg-gray-900 hover:bg-gray-800 text-white'
                    }`}
                >
                    {plan.buttonText}
                </button>

                {/* No credit card required note */}
                {plan.noCC && (
                    <p className="text-xs text-center text-gray-500 mt-2">
                        No credit card required
                    </p>
                )}
            </div>
        );
    };

    const statusConfig = {
        high: {
            icon: <Target className="w-5 h-5 text-red-500" />,
            title: 'Low Match Rate',
            description: 'You might receive fewer notifications than expected.',
            bg: 'bg-red-50',
            border: 'border-red-100',
            text: 'text-red-700',
            iconBg: 'bg-red-100'
        },
        medium: {
            icon: <ChartBar className="w-5 h-5 text-yellow-500" />,
            title: 'Moderate Match Rate',
            description: 'Consider adjusting criteria for more options.',
            bg: 'bg-yellow-50',
            border: 'border-yellow-100',
            text: 'text-yellow-700',
            iconBg: 'bg-yellow-100'
        },
        low: {
            icon: <Sparkles className="w-5 h-5 text-green-500" />,
            title: 'Great Match Rate',
            description: 'Your criteria are well optimized.',
            bg: 'bg-green-50',
            border: 'border-green-100',
            text: 'text-green-700',
            iconBg: 'bg-green-100'
        }
    };

    const renderAnalysisResults = () => {
        if (!analysis) return null;

        // Determine the risk level configuration
        const riskLevel = analysis.riskLevel || 'medium'; // Default to 'medium' if not provided
        const { bg, text, border } = statusConfig[riskLevel] || statusConfig.medium;

        return (
            <div className="space-y-6">
                {/* Analysis Results */}
                <div className={`bg-gradient-to-r ${bg} rounded-xl p-6 ${border}`}>
                    <div className="text-center">
                        <div
                            className={`inline-flex items-center px-4 py-2 ${bg} ${text} rounded-full text-sm font-medium mb-4`}
                        >
                            Analysis Complete
                        </div>
                        <h1 className="text-2xl md:text-3xl font-bold text-gray-900">
                            {analysis.totalListings} Properties Match Your Criteria
                        </h1>
                        <p className="text-gray-600 mt-2">
                            You'll receive approximately{' '}
                            <span className="font-semibold">{analysis.avgPerDay} new listings per day</span>
                        </p>
                    </div>
                </div>

                {/* Dismissible Suggestion Banner (if suggestions exist) */}
                {analysis.suggestions && analysis.suggestions.length > 0 && showSuggestionBanner && (
                    <div className="bg-blue-50 rounded-xl p-4 border border-blue-100 flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                            <Lightbulb className="w-5 h-5 text-blue-600" />
                            <span className="text-sm text-gray-700">
                            {analysis.suggestions[0] || "We have tips to improve your search!"}
                        </span>
                        </div>
                        <div className="flex space-x-3">
                            <button
                                onClick={() => setShowSuggestionBanner(false)}
                                className="text-sm text-blue-600 hover:text-blue-800 font-medium"
                            >
                                Dismiss
                            </button>
                            <button
                                onClick={() => setIsSuggestionsModalOpen(true)}
                                className="text-sm font-medium bg-blue-600 text-white px-3 py-1 rounded-md hover:bg-blue-700 transition-colors"
                            >
                                See More
                            </button>
                        </div>
                    </div>
                )}

                {/* Plan Selection - Mobile */}
                <SubscriptionSelector
                    onSelectPlan={setSelectedPlan}
                    onSubscribe={handleSubscriptionClick}
                />

                {/* Testimonials */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {testimonials.map((testimonial, index) => (
                        <div key={index} className="bg-gray-50 p-4 rounded-lg">
                            <p className="text-sm text-gray-600">"{testimonial.text}"</p>
                            <div className="mt-2">
                                <div className="font-medium text-gray-900">{testimonial.name}</div>
                                <div className="text-xs text-gray-500">
                                    {testimonial.role} • {testimonial.location}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="text-center text-sm text-gray-500">
                    <p>* Alert times are approximate and depend on when properties are listed</p>
                </div>

                {/* Suggestions Modal */}
                {isSuggestionsModalOpen && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
                        <div className="bg-white rounded-2xl max-w-md w-full p-6 relative">
                            <button
                                onClick={() => setIsSuggestionsModalOpen(false)}
                                className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
                            >
                                <X className="h-6 w-6" />
                            </button>

                            <div className="text-center mb-4">
                                <h2 className="text-xl font-bold text-gray-900 mb-2">
                                    Suggestions to Improve Matches
                                </h2>
                                <p className="text-gray-600 text-sm">
                                    Adjust your criteria for better results
                                </p>
                            </div>

                            <div className="space-y-3">
                                {analysis.suggestions.map((suggestion, index) => (
                                    <div
                                        key={index}
                                        className="flex items-center space-x-2 p-2 rounded-lg bg-gray-50 border border-gray-100"
                                    >
                                        <ArrowRight className="w-4 h-4 text-blue-600" />
                                        <span className="text-sm text-gray-700">{suggestion}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };
    const renderLoading = () => (
        <div className="flex flex-col items-center text-center">
            <h1 className="text-2xl font-bold text-gray-900 mb-6">
                Analyzing Property Market
            </h1>

            <div className="w-full max-w-md mb-8">
                <div className="h-2 w-full bg-gray-200 rounded-full overflow-hidden">
                    <div
                        className="h-full bg-blue-600 rounded-full transition-all duration-300"
                        style={{width: `${progressValue}%`}}
                    />
                </div>
                <p className="text-sm text-gray-500 mt-2">This may take a minute...</p>
            </div>

            <div className="flex justify-center mb-8">
                <Loader2 className="h-16 w-16 text-blue-600 animate-spin"/>
            </div>

            <div className="bg-blue-50 p-4 rounded-lg max-w-md mb-6">
                <p className="text-blue-800 text-center">
                    <span className="font-medium">Did you know?</span>
                    <br/>
                    {tips[currentTip]}
                </p>
            </div>

            <p className="text-gray-600">
                We're analyzing rental market data in your area to provide relevant property matches.
            </p>
        </div>
    );

    const renderError = () => (
        <div className="flex flex-col items-center text-center">
            <div className="flex justify-center mb-6">
                <AlertCircle className="h-16 w-16 text-red-500"/>
            </div>

            <h1 className="text-2xl font-bold text-gray-900 mb-4">Analysis Error</h1>
            <p className="text-gray-600 mb-8">{error}</p>

            <button
                onClick={() => navigate("/dashboard")}
                className="px-6 py-3 text-white bg-blue-600 rounded-md hover:bg-blue-700 transition-colors"
            >
                Continue to Dashboard
            </button>
        </div>
    );

    const handleSubscribe = async (tier) => {
        const params = new URLSearchParams(location.search);
        const analysisId = params.get("id");

        const token = localStorage.getItem("token");
        const stripe = await stripePromise;
        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/stripe/create-checkout-session`,
            {
                tier: tier,
                successUrl: `${window.location.origin}/success?analysis_id=${analysisId}`,
                cancelUrl: `${window.location.origin}/cancel`,
                metadata: {
                    analysisId: analysisId
                }
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );

        const sessionId = response.data;

        if (sessionId.startsWith("free_subscription_")) {
            window.location.href = `/success`;
            return;
        }

        const result = await stripe.redirectToCheckout({
            sessionId: sessionId,
        });

        if (result.error) {
            console.error(result.error.message);
        }
    };

    const handleSubscriptionClick = (tier) => {
        const token = localStorage.getItem('token');
        if (!token) {
            setSelectedTier(tier);
            setShowAuthModal(true);
            return;
        }
        handleSubscribe(tier);
    };
    const renderAuthModal = () => {
        if (!showAuthModal) return null;

        return (
            <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
                <div className="bg-white rounded-2xl max-w-md w-full p-8 relative">
                    <button
                        onClick={() => setShowAuthModal(false)}
                        className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
                    >
                        <X className="h-6 w-6" />
                    </button>

                    <div className="text-center mb-8">
                        <h2 className="text-2xl font-bold text-gray-900 mb-4">
                            Create an Account
                        </h2>
                        <p className="text-gray-600">
                            To start your {selectedTier} subscription, please create an account or sign in.
                        </p>
                    </div>

                    <div className="space-y-4">
                        <button
                            onClick={() => {
                                localStorage.setItem('pendingSubscription', selectedTier);
                                navigate('/register');
                            }}
                            className="w-full py-3 px-6 rounded-lg bg-blue-600 hover:bg-blue-700 text-white font-medium transition-colors"
                        >
                            Create Account
                        </button>

                        <div className="text-center">
                            <span className="text-gray-500">Already have an account?</span>
                        </div>

                        <button
                            onClick={() => {
                                localStorage.setItem('pendingSubscription', selectedTier);
                                navigate('/login');
                            }}
                            className="w-full py-3 px-6 rounded-lg border border-gray-300 hover:bg-gray-50 text-gray-700 font-medium transition-colors"
                        >
                            Sign In
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <Helmet>
                <title>Analyzing Property Market | Dwellio</title>
                <meta
                    name="description"
                    content="Analyzing property market data to optimize your search"
                />
            </Helmet>
            <Navbar/>

            <main className="min-h-screen bg-gray-50 py-12" style={{paddingTop: '0rem'}}>
                <div className="w-full md:max-w-5xl mx-auto px-0 sm:px-6 lg:px-8">
                    <div className="bg-white rounded-xl shadow-md overflow-hidden p-3 sm:p-8">
                        {loading ? (
                            renderLoading()
                        ) : error ? (
                            renderError()
                        ) : (
                            renderAnalysisResults()
                        )}
                    </div>
                </div>
            </main>
            {renderAuthModal()}
            <Footer/>
        </>
    );
};

export default PropertyAnalysisPage;