import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {AuthProvider} from './components/Context/AuthProvider';
import {ProtectedRoute, ProtectedAdminRoute, PublicOnlyRoute} from './components/Helpers/ProtectedRoutes';
import Register from './components/Auth/Register';
import Login from './components/Auth/Login';
import Profile from './components/Profile';
import Dashboard from './components/Dashboard';
import Home from './components/Home';
import Subscriptions from './components/Subscriptions';
import Success from './components/Success';
import Cancel from './components/Cancel';
import EmailConfirmed from './components/Auth/EmailConfirmed';
import RegistrationSuccess from './components/Auth/RegistrationSuccess';
import SubscriptionCheckout from './components/SubscriptionCheckout';
import CookieConsent from './components/TermsAndConditions/CookieConsent';
import Terms from './components/TermsAndConditions/Terms';
import Privacy from './components/TermsAndConditions/Privacy';
import Contact from './components/Contact';
import ForgotPassword from './components/Auth/ForgotPassword';
import ResetPassword from './components/Auth/ResetPassword';
import AdminLogin from './components/Admin/AdminLogin';
import BackgroundTaskDashboard from './components/Admin/BackgroundTaskDashboard';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {GoogleOAuthProvider} from '@react-oauth/google';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import PropertySearchLanding from "./components/PropertySearchLanding";
import HowItWorks from './components/HowItWorks';
import PropertyRedirect from "./components/Helpers/PropertyRedirect";
import AboutUs from './components/AboutUs';
import NotFound from './components/NotFound';
import {useLocation} from 'react-router-dom';
import PropertySearchPage from "./components/PropertySearchPage";
import PropertyAnalysisPage from "./components/PropertyAnalysisPage";
import AdminDashboard from "./components/Admin/AdminDashboard";

const getCanonicalUrl = (path) => {
    return `https://dwellio.co.uk${path}`;
};

const RouteWrapper = ({children}) => {
    const location = useLocation();

    return (
        <HelmetProvider>
            <Helmet>
                <link rel="canonical" href={getCanonicalUrl(location.pathname)}/>
            </Helmet>
            {children}
        </HelmetProvider>
    );
};

function App() {
    return (
        <GoogleOAuthProvider clientId="490817663361-juu345lt02ov0hr0v4kiec5fhq3uauto.apps.googleusercontent.com">
            <AuthProvider>
                <div className="App">
                    <RouteWrapper>
                        <Routes>
                            {/* Public routes - accessible to everyone */}
                            <Route path="/" element={<Home/>}/>
                            <Route path="/contact" element={<Contact/>}/>
                            <Route path="/terms" element={<Terms/>}/>
                            <Route path="/privacy" element={<Privacy/>}/>
                            <Route path="/email-confirmed" element={<EmailConfirmed/>}/>
                            <Route path="/subscriptions" element={<Subscriptions/>}/>
                            <Route path="/rent/:city" element={<PropertySearchLanding/>}/>
                            <Route path="/how-it-works" element={<HowItWorks/>}/>
                            <Route path="/property/redirect/:id" element={<PropertyRedirect/>}/>
                            <Route path="/about-us" element={<AboutUs/>}/>
                            <Route path="/property-search" element={<PropertySearchPage />} />
                            <Route path="/property-analysis" element={<PropertyAnalysisPage />} />
                            <Route path="/admin" element={<AdminLogin/>}/>
                            {/* Public routes that redirect to dashboard if logged in */}
                            <Route path="/register" element={
                                <PublicOnlyRoute>
                                    <Register/>
                                </PublicOnlyRoute>
                            }/>
                            <Route path="/login" element={
                                <PublicOnlyRoute>
                                    <Login/>
                                </PublicOnlyRoute>
                            }/>
                            <Route path="/forgot-password" element={
                                <PublicOnlyRoute>
                                    <ForgotPassword/>
                                </PublicOnlyRoute>
                            }/>
                            <Route path="/reset-password" element={
                                <PublicOnlyRoute>
                                    <ResetPassword/>
                                </PublicOnlyRoute>
                            }/>
                            <Route path="/registration-success" element={
                                <PublicOnlyRoute>
                                    <RegistrationSuccess/>
                                </PublicOnlyRoute>
                            }/>

                            {/* Protected user routes - require authentication */}
                            <Route path="/profile" element={
                                <ProtectedRoute>
                                    <Profile/>
                                </ProtectedRoute>
                            }/>
                            <Route path="/dashboard" element={
                                <ProtectedRoute>
                                    <Dashboard/>
                                </ProtectedRoute>
                            }/>
                            <Route path="/subscription-checkout" element={
                                <ProtectedRoute>
                                    <SubscriptionCheckout/>
                                </ProtectedRoute>
                            }/>
                            <Route path="/success" element={
                                <ProtectedRoute>
                                    <Success/>
                                </ProtectedRoute>
                            }/>
                            <Route path="/cancel" element={
                                <ProtectedRoute>
                                    <Cancel/>
                                </ProtectedRoute>
                            }/>

                            <Route path="/admin/dashboard" element={
                                <ProtectedAdminRoute>
                                    <AdminDashboard />
                                </ProtectedAdminRoute>
                            }/>
                            <Route path="/admin/users" element={
                                <ProtectedAdminRoute>
                                    <AdminDashboard />
                                </ProtectedAdminRoute>
                            }/>
                            <Route path="/admin/tasks" element={
                                <ProtectedAdminRoute>
                                    <AdminDashboard />
                                </ProtectedAdminRoute>
                            }/>
                            <Route path="*" element={<NotFound/>}/>
                        </Routes>
                    </RouteWrapper>
                    <CookieConsent/>
                </div>
            </AuthProvider>
        </GoogleOAuthProvider>
    );
}

export default App;