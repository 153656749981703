import React from 'react';
import Navbar from '../NavMenu';
import { ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Terms = () => {
    const navigate = useNavigate();

    return (
        <>
            <Helmet>
                <title>Terms and Conditions - Dwellio</title>
                <meta name="description" content="Read Dwellio's terms and conditions for using our property alert service. Learn about our subscription plans, user accounts, and service policies." />
                <link rel="canonical" href="https://dwellio.co.uk/terms" />
                <meta property="og:title" content="Terms and Conditions - Dwellio" />
                <meta property="og:description" content="Read Dwellio's terms and conditions for using our property alert service." />
                <meta property="og:url" content="https://dwellio.co.uk/terms" />
                <meta property="og:type" content="website" />
            </Helmet>
            <Navbar />
            <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
                <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
                    {/* Back Button */}
                    <button
                        onClick={() => navigate(-1)}
                        className="flex items-center text-gray-600 hover:text-gray-900 mb-8"
                    >
                        <ArrowLeft className="h-5 w-5 mr-2" />
                        Back
                    </button>

                    {/* Header Section */}
                    <div className="text-center mb-12">
                        <h1 className="text-4xl font-bold text-gray-900">Terms and Conditions</h1>
                        <p className="mt-4 text-gray-600">Last updated: January 19, 2025</p>
                    </div>

                    {/* Terms Content */}
                    <div className="prose max-w-none">
                        <section className="mb-12">
                            <h2 className="text-2xl font-bold text-gray-900 mb-4">1. Introduction</h2>
                            <p className="text-gray-600 mb-4">
                                Welcome to Dwellio ("we," "our," or "us"). By accessing or using our property alert service at dwellio.com (the "Service"), you agree to be bound by these Terms and Conditions ("Terms").
                            </p>
                        </section>

                        <section className="mb-12">
                            <h2 className="text-2xl font-bold text-gray-900 mb-4">2. Service Description</h2>
                            <p className="text-gray-600 mb-4">Dwellio is a property alert service that provides:</p>
                            <ul className="list-disc pl-6 text-gray-600 mb-4">
                                <li className="mb-2">Real-time property notifications</li>
                                <li className="mb-2">Property market analytics</li>
                                <li className="mb-2">Customized property searches</li>
                                <li className="mb-2">Market insights and trends</li>
                                <li className="mb-2">Saved search preferences</li>
                            </ul>
                        </section>

                        <section className="mb-12">
                            <h2 className="text-2xl font-bold text-gray-900 mb-4">3. Subscription Plans</h2>
                            <h3 className="text-xl font-semibold text-gray-900 mb-3">3.1. We offer the following subscription tiers:</h3>
                            <ul className="list-disc pl-6 text-gray-600 mb-4">
                                <li className="mb-2">Free Plan</li>
                                <li className="mb-2">Basic Plan (£6.99/month)</li>
                                <li className="mb-2">Pro Plan (£12.99/month)</li>
                            </ul>

                            <h3 className="text-xl font-semibold text-gray-900 mb-3">3.2. Trial Period:</h3>
                            <ul className="list-disc pl-6 text-gray-600 mb-4">
                                <li className="mb-2">Pro Plan includes a 14-day free trial</li>
                                <li className="mb-2">You may cancel at any time during the trial period</li>
                                <li className="mb-2">Payment information may be required to start trial</li>
                            </ul>

                            <h3 className="text-xl font-semibold text-gray-900 mb-3">3.3. Billing:</h3>
                            <ul className="list-disc pl-6 text-gray-600 mb-4">
                                <li className="mb-2">Subscription fees are billed in advance on a monthly basis</li>
                                <li className="mb-2">All payments are processed securely through Stripe</li>
                                <li className="mb-2">No refunds for partial month subscriptions</li>
                            </ul>
                        </section>

                        <section className="mb-12">
                            <h2 className="text-2xl font-bold text-gray-900 mb-4">4. User Accounts</h2>
                            <h3 className="text-xl font-semibold text-gray-900 mb-3">4.1. To use the Service, you must:</h3>
                            <ul className="list-disc pl-6 text-gray-600 mb-4">
                                <li className="mb-2">Be at least 18 years old</li>
                                <li className="mb-2">Register for an account</li>
                                <li className="mb-2">Provide accurate and complete information</li>
                                <li className="mb-2">Keep your account credentials secure</li>
                            </ul>

                            <h3 className="text-xl font-semibold text-gray-900 mb-3">4.2. You are responsible for:</h3>
                            <ul className="list-disc pl-6 text-gray-600 mb-4">
                                <li className="mb-2">Maintaining account confidentiality</li>
                                <li className="mb-2">All activities under your account</li>
                                <li className="mb-2">Notifying us of unauthorized access</li>
                            </ul>
                        </section>

                        <section className="mb-12">
                            <h2 className="text-2xl font-bold text-gray-900 mb-4">5. Property Information</h2>
                            <h3 className="text-xl font-semibold text-gray-900 mb-3">5.1. While we strive for accuracy:</h3>
                            <ul className="list-disc pl-6 text-gray-600 mb-4">
                                <li className="mb-2">Property information is provided "as is"</li>
                                <li className="mb-2">We don't guarantee complete accuracy</li>
                                <li className="mb-2">Users should verify information independently</li>
                                <li className="mb-2">Market analysis and predictions are estimates only</li>
                            </ul>

                            <h3 className="text-xl font-semibold text-gray-900 mb-3">5.2. Alert Timing:</h3>
                            <ul className="list-disc pl-6 text-gray-600 mb-4">
                                <li className="mb-2">Notification delays may vary by subscription tier</li>
                                <li className="mb-2">Real-time alerts are subject to data provider limitations</li>
                                <li className="mb-2">System maintenance may affect alert delivery</li>
                            </ul>
                        </section>

                        <section className="mb-12">
                            <h2 className="text-2xl font-bold text-gray-900 mb-4">6. User Conduct</h2>
                            <p className="text-gray-600 mb-4">You agree not to:</p>
                            <ul className="list-disc pl-6 text-gray-600 mb-4">
                                <li className="mb-2">Scrape or mass-download information</li>
                                <li className="mb-2">Share account access with others</li>
                                <li className="mb-2">Use the service for illegal purposes</li>
                                <li className="mb-2">Interfere with service operation</li>
                                <li className="mb-2">Circumvent subscription limitations</li>
                            </ul>
                        </section>

                        <section className="mb-12">
                            <h2 className="text-2xl font-bold text-gray-900 mb-4">7. Privacy and Data</h2>
                            <h3 className="text-xl font-semibold text-gray-900 mb-3">7.1. We collect and process data as described in our Privacy Policy, including:</h3>
                            <ul className="list-disc pl-6 text-gray-600 mb-4">
                                <li className="mb-2">Account information</li>
                                <li className="mb-2">Search preferences</li>
                                <li className="mb-2">Usage statistics</li>
                                <li className="mb-2">Payment information</li>
                            </ul>

                            <h3 className="text-xl font-semibold text-gray-900 mb-3">7.2. Communications:</h3>
                            <ul className="list-disc pl-6 text-gray-600 mb-4">
                                <li className="mb-2">We send service-related emails</li>
                                <li className="mb-2">Alert frequencies vary by subscription tier</li>
                                <li className="mb-2">Users can manage notification preferences</li>
                            </ul>
                        </section>

                        <section className="mb-12">
                            <h2 className="text-2xl font-bold text-gray-900 mb-4">8. Cancellation and Termination</h2>
                            <h3 className="text-xl font-semibold text-gray-900 mb-3">8.1. You may cancel your subscription:</h3>
                            <ul className="list-disc pl-6 text-gray-600 mb-4">
                                <li className="mb-2">At any time through your account settings</li>
                                <li className="mb-2">By contacting customer support</li>
                                <li className="mb-2">Before the next billing cycle</li>
                            </ul>

                            <h3 className="text-xl font-semibold text-gray-900 mb-3">8.2. We may terminate accounts for:</h3>
                            <ul className="list-disc pl-6 text-gray-600 mb-4">
                                <li className="mb-2">Terms violation</li>
                                <li className="mb-2">Extended inactivity</li>
                                <li className="mb-2">Failed payments</li>
                                <li className="mb-2">Fraudulent activity</li>
                            </ul>
                        </section>

                        <section className="mb-12">
                            <h2 className="text-2xl font-bold text-gray-900 mb-4">9. Changes to Service</h2>
                            <p className="text-gray-600 mb-4">We reserve the right to:</p>
                            <ul className="list-disc pl-6 text-gray-600 mb-4">
                                <li className="mb-2">Modify or discontinue features</li>
                                <li className="mb-2">Adjust subscription pricing</li>
                                <li className="mb-2">Update these Terms</li>
                                <li className="mb-2">Change alert frequencies or limitations</li>
                            </ul>
                        </section>

                        <section className="mb-12">
                            <h2 className="text-2xl font-bold text-gray-900 mb-4">10. Limitation of Liability</h2>
                            <h3 className="text-xl font-semibold text-gray-900 mb-3">10.1. We are not liable for:</h3>
                            <ul className="list-disc pl-6 text-gray-600 mb-4">
                                <li className="mb-2">Property purchase decisions</li>
                                <li className="mb-2">Missed property opportunities</li>
                                <li className="mb-2">Data inaccuracies</li>
                                <li className="mb-2">Service interruptions</li>
                                <li className="mb-2">Third-party content</li>
                            </ul>

                            <h3 className="text-xl font-semibold text-gray-900 mb-3">10.2. Maximum liability limited to:</h3>
                            <ul className="list-disc pl-6 text-gray-600 mb-4">
                                <li className="mb-2">Your last month's subscription fee</li>
                                <li className="mb-2">Or $100, whichever is less</li>
                            </ul>
                        </section>

                        <section className="mb-12">
                            <h2 className="text-2xl font-bold text-gray-900 mb-4">11. Intellectual Property</h2>
                            <h3 className="text-xl font-semibold text-gray-900 mb-3">11.1. You maintain ownership of:</h3>
                            <ul className="list-disc pl-6 text-gray-600 mb-4">
                                <li className="mb-2">Your account information</li>
                                <li className="mb-2">Your saved searches</li>
                                <li className="mb-2">Your custom alerts</li>
                            </ul>

                            <h3 className="text-xl font-semibold text-gray-900 mb-3">11.2. We retain all rights to:</h3>
                            <ul className="list-disc pl-6 text-gray-600 mb-4">
                                <li className="mb-2">Service design and features</li>
                                <li className="mb-2">Analytics and insights</li>
                                <li className="mb-2">Market analysis tools</li>
                                <li className="mb-2">Aggregated data</li>
                            </ul>
                        </section>

                        <section className="mb-12">
                            <h2 className="text-2xl font-bold text-gray-900 mb-4">12. Governing Law</h2>
                            <p className="text-gray-600 mb-4">These Terms are governed by UK law. Any disputes will be resolved in the courts of UK.</p>
                        </section>

                        <section className="mb-12">
                            <h2 className="text-2xl font-bold text-gray-900 mb-4">13. Contact Us</h2>
                            <p className="text-gray-600 mb-4">For questions about these Terms:</p>
                            <ul className="list-disc pl-6 text-gray-600 mb-4">
                                <li className="mb-2">Email: Support@dwellio.co.uk</li>
                            </ul>
                        </section>

                        <section className="mb-12">
                            <h2 className="text-2xl font-bold text-gray-900 mb-4">14. Changes to Terms</h2>
                            <p className="text-gray-600 mb-4">We may update these Terms at any time. We will notify users of material changes via email or service notification.</p>
                        </section>
                    </div>

                    {/* Footer Note */}
                    <div className="mt-12 p-6 bg-gray-50 rounded-lg">
                        <p className="text-gray-600 text-center">
                            By using Dwellio, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Terms;