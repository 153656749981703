import React, { useState, useEffect } from 'react';
import { Clock, AlertCircle, User, CreditCard } from 'lucide-react';

const RecentActivities = () => {
    const [activities, setActivities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchActivities = async () => {
            try {
                setLoading(true);
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/Admin/recent-activities`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch recent activities');
                }

                const data = await response.json();
                setActivities(data);
                setError(null);
            } catch (err) {
                setError(err.message);
                console.error('Error fetching recent activities:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchActivities();
        // Refresh every 2 minutes
        const interval = setInterval(fetchActivities, 120000);
        return () => clearInterval(interval);
    }, []);

    const getActivityIcon = (type) => {
        switch (type) {
            case 'UserRegistration':
                return <User className="h-5 w-5 text-blue-500" />;
            case 'SubscriptionChange':
                return <CreditCard className="h-5 w-5 text-purple-500" />;
            default:
                return <Clock className="h-5 w-5 text-gray-500" />;
        }
    };

    if (loading) {
        return (
            <div className="flex justify-center p-6">
                <div className="animate-spin h-8 w-8 border-4 border-blue-500 rounded-full border-t-transparent"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="bg-red-50 text-red-600 p-4 rounded-lg flex items-center">
                <AlertCircle className="h-5 w-5 mr-2" />
                <span>Error loading recent activities: {error}</span>
            </div>
        );
    }

    return (
        <div className="mt-8">
            <h2 className="text-xl font-semibold mb-4">Recent Activities</h2>
            <div className="bg-white rounded-lg shadow border border-gray-100 overflow-hidden">
                {activities.length === 0 ? (
                    <div className="p-6 text-center text-gray-500">No recent activities found</div>
                ) : (
                    <div className="divide-y divide-gray-100">
                        {activities.map((activity, index) => (
                            <div key={index} className="p-4 flex items-start">
                                <div className="mr-3 mt-1">
                                    {getActivityIcon(activity.type)}
                                </div>
                                <div className="flex-1">
                                    <p className="text-sm text-gray-700">{activity.details}</p>
                                    <p className="text-xs text-gray-500 mt-1">
                                        {new Date(activity.date).toLocaleString()}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default RecentActivities;