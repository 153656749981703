import React, { useState } from 'react';
import { Check, Info, Star, MapPin, Clock, List } from 'lucide-react';

const SubscriptionSelector = ({ onSelectPlan, onSubscribe }) => {
    const [selectedPlan, setSelectedPlan] = useState('pro');
    const [showComparison, setShowComparison] = useState(false);

    const plans = {
        free: {
            name: "Free",
            price: "£0",
            description: "with basic features",
            quality: "Basic",
            listings: "5 per day",
            delay: "24h"
        },
        basic: {
            name: "Basic",
            price: "£6.99",
            description: "with priority alerts",
            quality: "Good",
            listings: "20 per day",
            delay: "3 per day"
        },
        pro: {
            name: "Pro",
            price: "£12.99",
            description: "with premium features",
            quality: "Best",
            listings: "Unlimited",
            delay: "15min",
            recommended: true
        }
    };

    const handleSelect = (planKey) => {
        setSelectedPlan(planKey);
        if (onSelectPlan) {
            onSelectPlan(planKey);
        }
    };

    const handleSubscribe = () => {
        if (onSubscribe) {
            onSubscribe(selectedPlan);
        }
    };

    // Feature descriptions for the plan comparison
    const featureRows = [
        {
            name: "Monthly price",
            free: "£0",
            basic: "£6.99",
            pro: "£12.99"
        },
        {
            name: "Alert frequency",
            free: "24h delay",
            basic: "3 per day",
            pro: "15min alerts"
        },
        {
            name: "Properties per day",
            free: "5",
            basic: "20",
            pro: "Unlimited"
        },
        {
            name: "Search areas",
            free: "1",
            basic: "3",
            pro: "10"
        },
        {
            name: "Listing details",
            free: "Basic",
            basic: "Standard",
            pro: "Comprehensive"
        },
        {
            name: "Priority access",
            free: "",
            basic: "",
            pro: <Check className="mx-auto h-5 w-5 text-green-500" />
        }
    ];

    return (
        <div className="w-full max-w-4xl mx-auto">
            <div className="mb-4">
                <h2 className="text-xl font-semibold text-center mb-1">Choose the plan that's right for you</h2>
                <p className="text-gray-600 text-center text-sm">Change or cancel your plan anytime</p>
            </div>

            {/* Plan Selection - Mobile (Stacked), Desktop (Horizontal Cards) */}
            <div className="mb-6">
                {/* Mobile View (below sm) */}
                <div className="grid grid-cols-3 gap-2 mb-6 sm:hidden">
                    {Object.keys(plans).map((planKey) => (
                        <div
                            key={planKey}
                            onClick={() => handleSelect(planKey)}
                            className={`
                                cursor-pointer p-3 text-center transition-all relative
                                ${selectedPlan === planKey
                                ? 'bg-blue-600 text-white border border-blue-600'
                                : 'bg-white text-gray-800 border border-gray-200 hover:border-blue-400'}
                                rounded-lg
                            `}
                        >
                            <div className="font-medium">{plans[planKey].name}</div>
                            <div className="text-xs mt-1">{plans[planKey].description}</div>

                            {plans[planKey].recommended && (
                                <div className="absolute -top-2 left-0 right-0 flex justify-center">
                                    <span className="bg-blue-100 text-blue-700 text-xs px-2 py-0.5 rounded-full text-center">
                                        Most Popular
                                    </span>
                                </div>
                            )}
                        </div>
                    ))}
                </div>

                {/* Desktop View (above sm) - Horizontal Cards like Netflix */}
                <div className="hidden sm:grid grid-cols-3 gap-4">
                    {Object.keys(plans).map((planKey) => (
                        <div
                            key={planKey}
                            onClick={() => handleSelect(planKey)}
                            className={`
        cursor-pointer p-4 border rounded-xl shadow-lg transition-all duration-200
        ${selectedPlan === planKey
                                ? 'border-blue-600 bg-gradient-to-br from-blue-50 to-white'
                                : 'border-gray-200 bg-white hover:border-blue-400 hover:bg-gray-50'}
        hover:shadow-xl
    `}
                        >
                            <div className="flex justify-between items-start mb-2">
                                <div>
                                    <h3 className="font-semibold">{plans[planKey].name}</h3>
                                    <p className="text-sm text-gray-600 mt-1">{plans[planKey].description}</p>
                                </div>
                                <div className="text-right">
                                    <div className="text-lg font-bold">{plans[planKey].price}</div>
                                    <div className="text-sm text-gray-500">per month</div>
                                </div>
                            </div>

                            {plans[planKey].recommended && (
                                <div className="mb-2">
            <span className="bg-blue-100 text-blue-700 text-xs px-2 py-0.5 rounded-full">
                Most Popular
            </span>
                                </div>
                            )}

                            <div className="space-y-2 text-sm">
                                {featureRows.map((row, index) => (
                                    <div key={index} className="flex items-center">
                <span className="flex-1 text-left flex items-center">
                    {row.name === "Alert frequency" && <Clock className="h-4 w-4 text-gray-400 mr-2"/>}
                    {row.name === "Properties per day" && <List className="h-4 w-4 text-gray-400 mr-2"/>}
                    {row.name === "Search areas" && <MapPin className="h-4 w-4 text-gray-400 mr-2"/>}
                    {row.name === "Listing details" && <Info className="h-4 w-4 text-gray-400 mr-2"/>}
                    {row.name === "Priority access" && <Star className="h-4 w-4 text-gray-400 mr-2"/>}
                    {row.name}
                </span>
                                        {row[planKey] ? (
                                            typeof row[planKey] === 'string' ? (
                                                <span className="text-right flex-1 font-medium">{row[planKey]}</span>
                                            ) : (
                                                row[planKey]
                                            )
                                        ) : (
                                            <span className="text-right flex-1 text-gray-400">—</span>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Plan Details in Selected Plan Card - Same for Mobile and Desktop */}
            <div className="bg-white rounded-lg border border-gray-200 overflow-hidden mb-6">
                <div className="p-4 border-b border-gray-200">
                    <div className="flex justify-between items-center">
                        <div>
                            <h3 className="text-lg font-semibold">{plans[selectedPlan].name}</h3>
                            <p className="text-sm text-gray-600">
                                {selectedPlan === 'free'
                                    ? 'Test drive our rental alerts'
                                    : selectedPlan === 'basic'
                                        ? 'For active rental searchers'
                                        : 'For urgent rental needs'}
                            </p>
                        </div>
                        <div className="text-right">
                            <div className="text-2xl font-bold">{plans[selectedPlan].price}</div>
                            {selectedPlan !== 'free' && <div className="text-sm text-gray-500">per month</div>}
                        </div>
                    </div>
                </div>

                <div className="p-4">
                    <h4 className="font-medium mb-3">Included in your plan:</h4>
                    <div className="space-y-2">
                        {selectedPlan === 'free' && (
                            <>
                                <div className="flex items-center"><Check className="h-5 w-5 text-green-500 mr-2"/> 24h
                                    notification delay
                                </div>
                                <div className="flex items-center"><Check className="h-5 w-5 text-green-500 mr-2"/> 1
                                    search area
                                </div>
                                <div className="flex items-center"><Check className="h-5 w-5 text-green-500 mr-2"/> 5
                                    listings per day
                                </div>
                                <div className="flex items-center"><Check
                                    className="h-5 w-5 text-green-500 mr-2"/> Basic listing details
                                </div>
                            </>
                        )}
                        {selectedPlan === 'basic' && (
                            <>
                                <div className="flex items-center"><Check className="h-5 w-5 text-green-500 mr-2" /> 3 email alerts per day</div>
                                <div className="flex items-center"><Check className="h-5 w-5 text-green-500 mr-2" /> 3 search areas</div>
                                <div className="flex items-center"><Check className="h-5 w-5 text-green-500 mr-2" /> 20 listings per day</div>
                                <div className="flex items-center"><Check className="h-5 w-5 text-green-500 mr-2" /> Quick-view property details</div>
                            </>
                        )}
                        {selectedPlan === 'pro' && (
                            <>
                                <div className="flex items-center"><Check className="h-5 w-5 text-green-500 mr-2" /> 15min alerts</div>
                                <div className="flex items-center"><Check className="h-5 w-5 text-green-500 mr-2" /> 10 search areas</div>
                                <div className="flex items-center"><Check className="h-5 w-5 text-green-500 mr-2" /> Unlimited alerts</div>
                                <div className="flex items-center"><Check className="h-5 w-5 text-green-500 mr-2" /> Priority Access</div>
                            </>
                        )}
                    </div>
                </div>

                {/* Trial info for Pro plan */}
                {selectedPlan === 'pro' && (
                    <div className="p-4 bg-blue-50 border-t border-blue-100">
                        <div className="text-center text-blue-700 font-medium">
                            First 7 days free, then £12.99/month
                        </div>
                    </div>
                )}

                <div className="p-4 flex justify-center">
                    <button
                        onClick={handleSubscribe}
                        className={`
                            py-2 px-6 rounded-md font-medium text-white transition-colors
                            ${selectedPlan === 'pro'
                            ? 'bg-blue-600 hover:bg-blue-700'
                            : selectedPlan === 'basic'
                                ? 'bg-gray-800 hover:bg-gray-900'
                                : 'bg-green-600 hover:bg-green-700'}
                        `}
                    >
                        {selectedPlan === 'free'
                            ? 'Start With Free Plan'
                            : selectedPlan === 'pro'
                                ? 'Start 7-Day Free Trial'
                                : 'Subscribe Now'}
                    </button>
                </div>

                {selectedPlan === 'free' && (
                    <div className="px-4 pb-3 text-center">
                        <p className="text-xs text-gray-500">No credit card required</p>
                    </div>
                )}
            </div>

            {/* Plan Comparison - Mobile (Toggleable), Desktop (Hidden) */}
            <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
                {/* Mobile Comparison Toggle (below sm) */}
                <button
                    onClick={() => setShowComparison(!showComparison)}
                    className="w-full p-4 text-left font-medium text-blue-600 hover:bg-gray-100 sm:hidden"
                >
                    {showComparison ? 'Hide Plan Comparison' : 'Show Plan Comparison'}
                </button>

                {showComparison && (
                    <div className="sm:hidden">
                        {/* Mobile Version: Stacked Cards for Each Plan */}
                        {['free', 'basic', 'pro'].map((planKey) => (
                            <div key={planKey} className={`p-4 border-b border-gray-200 ${selectedPlan === planKey ? 'bg-gray-50' : ''}`}>
                                <h3 className="font-semibold mb-2">{plans[planKey].name}</h3>
                                {featureRows.map((row, index) => (
                                    <div key={index} className="flex justify-between items-center py-2">
                                        <span className="text-left font-medium">{row.name}</span>
                                        <span className="text-right">
                                            {typeof row[planKey] === 'string' ? (
                                                row[planKey]
                                            ) : (
                                                row[planKey]
                                            )}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                )}
            </div>

            <p className="text-xs text-center text-gray-500 mt-4">
                Prices shown include VAT. Cancel online anytime. Rental availability subject to market conditions.
            </p>
        </div>
    );
};

export default SubscriptionSelector;