import { Plus, AlertCircle } from 'lucide-react';
export default function EmptyState({ type, onAction }) {
    const content = {
        preferences: {
            title: "No alerts set",
            description: "Start by adding your first property search criteria to receive matching listings.",
            buttonText: "Add Your First Alert"
        },
        listings: {
            title: "No matched properties yet",
            description: "Add your property alerts to start receiving matching listings that meet your criteria.",
            buttonText: "Set Up Alerts"
        }
    };

    const { title, description, buttonText } = content[type];

    return (
        <div className="text-center py-12 px-4 bg-white rounded-lg border border-gray-100">
            <div className="flex flex-col items-center max-w-sm mx-auto">
                <div className="flex items-center justify-center w-12 h-12 rounded-full bg-blue-50 mb-4">
                    <AlertCircle className="h-6 w-6 text-blue-500" />
                </div>
                <h3 className="text-lg font-semibold text-gray-900 mb-2">
                    {title}
                </h3>
                <p className="text-gray-600 mb-6">
                    {description}
                </p>
                <button
                    onClick={onAction}
                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                    <Plus className="h-4 w-4 mr-2" />
                    {buttonText}
                </button>
            </div>
        </div>
    );
}