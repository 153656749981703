import React, {useEffect, useState} from 'react';
import {ChevronDown, ChevronUp, Target, ChartBar, Sparkles, Lightbulb, ArrowUpRight, Loader2} from 'lucide-react';

const MobileAnalysisSection = ({ preference, onDismiss }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isDismissed, setIsDismissed] = useState(false);

    // Show analyzing state for new preferences
    if (preference.isNew && (!preference.analysis || preference.analysisStatus === 'queued')) {
        return (
            <div className="mt-2 rounded-lg border border-blue-100 bg-blue-50 overflow-hidden">
                <div className="p-3">
                    <div className="flex items-start space-x-3">
                        <div className="bg-blue-100 p-1.5 rounded-md mt-0.5">
                            <Loader2 className="w-4 h-4 text-blue-500 animate-spin" />
                        </div>
                        <div className="space-y-2">
                            <h4 className="text-sm font-medium text-blue-700">
                                Analyzing Your Alert
                            </h4>
                            <p className="text-sm text-blue-600">
                                We're analyzing historical data to help optimize your alert settings.
                                This usually takes a few moments. You'll receive insights about:
                            </p>
                            <ul className="text-sm text-blue-600 space-y-1.5">
                                <li className="flex items-center space-x-2">
                                    <ChartBar className="w-4 h-4" />
                                    <span>Expected daily property matches</span>
                                </li>
                                <li className="flex items-center space-x-2">
                                    <Lightbulb className="w-4 h-4" />
                                    <span>Personalized optimization suggestions</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (!preference.analysis || isDismissed || !preference.analysis.isActive) return null;

    const { totalListings, averagePerDay, suggestions = [], riskLevel } = preference.analysis;

    const handleDismiss = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/PreferenceAnalysis/${preference.id}/analysis/dismiss`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.ok) {
                setIsDismissed(true);
                if (onDismiss) {
                    onDismiss(preference.id);
                }
            }
        } catch (error) {
            console.error('Error dismissing analysis:', error);
        }
    };

    const statusConfig = {
        high: {
            icon: <Target className="w-4 h-4 text-red-500" />,
            title: 'Low Match Rate',
            badge: 'bg-red-100 text-red-800',
            bg: 'bg-red-50',
            border: 'border-red-100',
            text: 'text-red-700',
            iconBg: 'bg-red-100'
        },
        medium: {
            icon: <ChartBar className="w-4 h-4 text-yellow-500" />,
            title: 'Moderate Match Rate',
            badge: 'bg-yellow-100 text-yellow-800',
            bg: 'bg-yellow-50',
            border: 'border-yellow-100',
            text: 'text-yellow-700',
            iconBg: 'bg-yellow-100'
        },
        low: {
            icon: <Sparkles className="w-4 h-4 text-green-500" />,
            title: 'Great Match Rate',
            badge: 'bg-green-100 text-green-800',
            bg: 'bg-green-50',
            border: 'border-green-100',
            text: 'text-green-700',
            iconBg: 'bg-green-100'
        }
    };

    const config = statusConfig[riskLevel] || statusConfig.medium;

    return (
        <div className={`mt-2 rounded-lg border ${config.border} ${config.bg} overflow-hidden`}>
            <button
                onClick={(e) => {
                    e.preventDefault();
                    setIsExpanded(!isExpanded);
                }}
                className="w-full p-3 flex items-center justify-between"
            >
                <div className="flex items-center justify-between space-x-3 px-3 py-2">
                    <div className={`${config.iconBg} p-2 rounded-md flex items-center`}>
                        {config.icon}
                    </div>
                    <div className="flex items-center">
                        <h4 className={`text-sm font-medium ${config.text} flex items-center`}>
                            {config.title}
                        </h4>
                    </div>
                </div>
                {isExpanded ?
                    <ChevronUp className={`w-4 h-4 ${config.text}`} /> :
                    <ChevronDown className={`w-4 h-4 ${config.text}`} />
                }
            </button>

            {isExpanded && (
                <div className="p-3 pt-0 scroll-mt-4">
                    <p className={`text-sm ${config.text} mb-3`}>
                        Based on the last 7 days ({totalListings} listings),
                        you can expect around <strong>{averagePerDay} properties per day</strong>.
                    </p>

                    {suggestions.length > 0 && (
                        <div className="space-y-2">
                            <div className="flex items-center space-x-2">
                                <Lightbulb className={`w-4 h-4 ${config.text}`} />
                                <span className={`text-xs font-medium ${config.text}`}>
                                    Suggestions to improve matches:
                                </span>
                            </div>

                            <div className="space-y-2">
                                {suggestions.map((suggestion, index) => (
                                    <div
                                        key={index}
                                        className={`flex items-start space-x-2 p-2 rounded-md bg-white/50 ${config.border}`}
                                    >
                                        <ArrowUpRight className={`w-4 h-4 ${config.text} mt-0.5 flex-shrink-0`} />
                                        <span className={`text-xs ${config.text}`}>{suggestion}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    <button
                        onClick={handleDismiss}
                        className={`mt-3 text-xs font-medium ${config.text} hover:opacity-75`}
                    >
                        Dismiss
                    </button>
                </div>
            )}
        </div>
    );
};

export default MobileAnalysisSection;