import React, { useState, useEffect, useCallback } from "react";
import { X, AlertCircle } from "lucide-react";
import axios from "axios";
import _ from "lodash";


const ImprovedPreferenceModal = ({
                                     showModal,
                                     handleCloseModal,
                                     isEditing,
                                     errorMessage,
                                     newPreference,
                                     handleChange,
                                     handleAddPreference,
                                     handleUpdatePreference,
                                     dropdownOptions,
                                     isAdding,
                                 }) => {
    const [citySuggestions, setCitySuggestions] = useState([]);
    const [loadingCities, setLoadingCities] = useState(false);
    const [isCityValid, setIsCityValid] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [showValidationAlert, setShowValidationAlert] = useState(false);

    // Required fields
    const requiredFields = {
        city: "Town or PostCode",
        priceRangeMin: "Min Price Monthly",
        priceRangeMax: "Max Price Monthly",
        propertyType: "Property Type",
        distanceRadius: "Distance Radius",
        minBedrooms: "Min Bedrooms",
        maxBedrooms: "Max Bedrooms"
    };

    // Validation function
    const validateForm = () => {
        const errors = {};
        let isValid = true;

        Object.entries(requiredFields).forEach(([field, label]) => {
            if (!newPreference[field]) {
                errors[field] = `${label} is required`;
                isValid = false;
            }
        });

        // Additional validation rules
        if (newPreference.priceRangeMin && newPreference.priceRangeMax &&
            Number(newPreference.priceRangeMin) > Number(newPreference.priceRangeMax)) {
            errors.priceRangeMax = "Max price should be greater than min price";
            isValid = false;
        }

        if (newPreference.minBedrooms && newPreference.maxBedrooms &&
            Number(newPreference.minBedrooms) > Number(newPreference.maxBedrooms)) {
            errors.maxBedrooms = "Max bedrooms should be greater than min bedrooms";
            isValid = false;
        }

        // Removed city validation as requested

        setValidationErrors(errors);
        setShowValidationAlert(!isValid);
        return isValid;
    };

    // Modified handle submit
    const handleSubmit = () => {
        if (validateForm()) {
            if (isEditing) {
                setShowWarning(true);
            } else {
                handleAddPreference();
            }
        }
    };

    const handleCityChange = (e) => {
        const { value } = e.target;
        handleChange(e);
        setIsCityValid(false);
        fetchCitySuggestions(value);
    };

    const handleCitySelection = (prediction) => {
        const cityName = prediction.structured_formatting.main_text;
        handleChange({
            target: { name: "city", value: cityName }
        });
        setCitySuggestions([]);
        setIsCityValid(true);
    };

    useEffect(() => {
        if (citySuggestions.some(pred => pred.description === newPreference.city)) {
            setIsCityValid(true);
        }
    }, [newPreference.city, citySuggestions]);

    const fetchCitySuggestions = useCallback(
        _.debounce(async (query) => {
            if (!query || query.length < 2) {
                setCitySuggestions([]);
                return;
            }
            setLoadingCities(true);
            try {
                const response = await axios.get('/api/Places/autocomplete', {
                    params: { input: query }
                });

                if (response.data.status === "OK" && response.data.predictions) {
                    setCitySuggestions(response.data.predictions);
                } else {
                    setCitySuggestions([]);
                }
            } catch (error) {
                console.error("Error fetching city suggestions:", error);
                setCitySuggestions([]);
            }
            setLoadingCities(false);
        }, 500),
        []
    );

    const handleModalClose = () => {
        // Reset validation states
        setValidationErrors({});
        setShowValidationAlert(false);
        // Call the parent's close handler
        handleCloseModal();
    };
    const getInputClasses = (fieldName) => {
        return `w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent
            ${validationErrors[fieldName]
            ? 'border-red-500 bg-red-50'
            : 'border-gray-300'}`;
    };

    const labelClasses = "block text-sm font-medium text-gray-700 mb-2";
    const formGroupClasses = "mb-6";

    return (
        <>
            {/* Main Modal */}
            <div className={`fixed inset-0 bg-black/50 z-50 ${showModal ? 'flex' : 'hidden'} items-center justify-center p-4`}>
                <div
                    className="bg-white rounded-xl w-full max-w-2xl mx-auto overflow-hidden max-h-[90vh] flex flex-col">
                    {/* Header */}
                    <div className="flex items-center justify-between p-3 border-b border-gray-200">
                        <h2 className="text-lg font-medium text-gray-900">
                            {isEditing ? "Edit Alert" : "Create Alert"}
                        </h2>
                        <button
                            onClick={handleModalClose}
                            className="text-gray-400 hover:text-gray-600 transition-colors"
                            aria-label="Close modal"
                        >
                            <X className="w-5 h-5"/>
                        </button>
                    </div>

                    {/* Body */}
                    <div className="p-4 overflow-y-auto">
                        {showValidationAlert && (
                            <div className="mb-4 p-3 bg-red-50 text-red-600 rounded flex items-center gap-2">
                                <AlertCircle className="h-4 w-4 flex-shrink-0"/>
                                <p className="text-sm">Please fill in all required fields correctly</p>
                            </div>
                        )}

                        {errorMessage && (
                            <div className="mb-6 p-4 bg-red-50 border border-red-200 text-red-700 rounded-lg">
                                {errorMessage}
                            </div>
                        )}

                        <form className="space-y-4">
                            <div className={formGroupClasses}>
                                <label className={labelClasses}>
                                    Town or PostCode <span className="text-red-500">*</span>
                                </label>
                                <div className="relative">
                                    <input
                                        type="text"
                                        name="city"
                                        value={newPreference.city || ""}
                                        onChange={handleCityChange}
                                        placeholder="e.g. Leeds, LS1, BD4 0RX"
                                        className={getInputClasses('city')}
                                        autoComplete="off"
                                    />
                                    {validationErrors.city && (
                                        <p className="mt-1 text-sm text-red-500">{validationErrors.city}</p>
                                    )}

                                    {/* City Suggestions Dropdown */}
                                    {citySuggestions.length > 0 && (
                                        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-y-auto">
                                            {citySuggestions.map((prediction, index) => (
                                                <button
                                                    key={prediction.place_id}
                                                    className="w-full px-4 py-2 text-left hover:bg-gray-100 focus:bg-gray-100 focus:outline-none"
                                                    onClick={() => handleCitySelection(prediction)}
                                                >
                                                    <div className="font-medium">
                                                        {prediction.structured_formatting.main_text}
                                                    </div>
                                                    <div className="text-sm text-gray-600">
                                                        {prediction.structured_formatting.secondary_text}
                                                    </div>
                                                </button>
                                            ))}
                                        </div>
                                    )}

                                    {/* Loading indicator */}
                                    {loadingCities && (
                                        <div className="absolute right-3 top-3">
                                            <div className="w-5 h-5 border-2 border-blue-600 border-t-transparent rounded-full animate-spin"></div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div>
                                    <label className={labelClasses}>
                                        Min Price Monthly <span className="text-red-500">*</span>
                                    </label>
                                    <select
                                        name="priceRangeMin"
                                        value={newPreference.priceRangeMin || ""}
                                        onChange={handleChange}
                                        className={getInputClasses('priceRangeMin')}
                                    >
                                        <option value="">Select Min Price</option>
                                        {dropdownOptions.PriceRange?.map((option, index) => (
                                            <option key={index} value={option}>{option}</option>
                                        ))}
                                    </select>
                                    {validationErrors.priceRangeMin && (
                                        <p className="mt-1 text-sm text-red-500">{validationErrors.priceRangeMin}</p>
                                    )}
                                </div>
                                <div>
                                    <label className={labelClasses}>
                                        Max Price Monthly <span className="text-red-500">*</span>
                                    </label>
                                    <select
                                        name="priceRangeMax"
                                        value={newPreference.priceRangeMax || ""}
                                        onChange={handleChange}
                                        className={getInputClasses('priceRangeMax')}
                                    >
                                        <option value="">Select Max Price</option>
                                        {dropdownOptions.PriceRange?.map((option, index) => (
                                            <option key={index} value={option}>{option}</option>
                                        ))}
                                    </select>
                                    {validationErrors.priceRangeMax && (
                                        <p className="mt-1 text-sm text-red-500">{validationErrors.priceRangeMax}</p>
                                    )}
                                </div>
                            </div>

                            <div className={formGroupClasses}>
                                <label className={labelClasses}>
                                    Property Type <span className="text-red-500">*</span>
                                </label>
                                <select
                                    name="propertyType"
                                    value={newPreference.propertyType || ""}
                                    onChange={handleChange}
                                    className={getInputClasses('propertyType')}
                                >
                                    <option value="">Select Property Type</option>
                                    {dropdownOptions.PropertyType?.map((option, index) => (
                                        <option key={index} value={option}>{option}</option>
                                    ))}
                                </select>
                                {validationErrors.propertyType && (
                                    <p className="mt-1 text-sm text-red-500">{validationErrors.propertyType}</p>
                                )}
                            </div>

                            <div className={formGroupClasses}>
                                <label className={labelClasses}>
                                    Distance Radius <span className="text-red-500">*</span>
                                </label>
                                <select
                                    name="distanceRadius"
                                    value={newPreference.distanceRadius || ""}
                                    onChange={handleChange}
                                    className={getInputClasses('distanceRadius')}
                                >
                                    <option value="">Select Distance</option>
                                    {dropdownOptions.DistanceRadius?.map((option, index) => (
                                        <option key={index} value={option}>{option}</option>
                                    ))}
                                </select>
                                {validationErrors.distanceRadius && (
                                    <p className="mt-1 text-sm text-red-500">{validationErrors.distanceRadius}</p>
                                )}
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div>
                                    <label className={labelClasses}>
                                        Min Bedrooms <span className="text-red-500">*</span>
                                    </label>
                                    <select
                                        name="minBedrooms"
                                        value={newPreference.minBedrooms === 0 ? "Studio" : newPreference.minBedrooms || ""}
                                        onChange={handleChange}
                                        className={getInputClasses('minBedrooms')}
                                    >
                                        <option value="">Select Min Bedrooms</option>
                                        {dropdownOptions.Bedrooms?.map((option, index) => (
                                            <option key={index} value={option === 0 ? "Studio" : option}>
                                                {option === 0 ? "Studio" : option}
                                            </option>
                                        ))}
                                    </select>
                                    {validationErrors.minBedrooms && (
                                        <p className="mt-1 text-sm text-red-500">{validationErrors.minBedrooms}</p>
                                    )}
                                </div>
                                <div>
                                    <label className={labelClasses}>
                                        Max Bedrooms <span className="text-red-500">*</span>
                                    </label>
                                    <select
                                        name="maxBedrooms"
                                        value={newPreference.maxBedrooms || ""}
                                        onChange={handleChange}
                                        className={getInputClasses('maxBedrooms')}
                                    >
                                        <option value="">Select Max Bedrooms</option>
                                        {dropdownOptions.Bedrooms?.map((option, index) => (
                                            <option key={index} value={option}>{option}</option>
                                        ))}
                                    </select>
                                    {validationErrors.maxBedrooms && (
                                        <p className="mt-1 text-sm text-red-500">{validationErrors.maxBedrooms}</p>
                                    )}
                                </div>
                            </div>

                            {/* Footer */}
                            <div className="flex flex-col-reverse sm:flex-row justify-end gap-2 mt-6">
                                <button
                                    type="button"
                                    onClick={handleCloseModal}
                                    className="w-full sm:w-auto px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    onClick={handleSubmit}
                                    disabled={isAdding}
                                    className="w-full sm:w-auto px-4 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center"
                                >
                                    {isAdding ? (
                                        <>
                                            <div
                                                className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin mr-2"></div>
                                            Processing...
                                        </>
                                    ) : (
                                        isEditing ? "Update Alert" : "Create Alert"
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {/* Warning Modal - remains the same */}
            {showWarning && (
                <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
                    <div className="bg-white rounded-xl p-6 max-w-md w-full mx-auto">
                        <h3 className="text-xl font-semibold mb-4">Confirm Alert Update</h3>
                        <p className="text-gray-600 mb-6">
                            Updating your alert will remove all existing matching listings associated with this alert.
                        </p>
                        <div className="flex flex-col-reverse sm:flex-row justify-end gap-3">
                            <button
                                onClick={() => setShowWarning(false)}
                                className="w-full sm:w-auto px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={() => {
                                    handleUpdatePreference();
                                    setShowWarning(false);
                                }}
                                className="w-full sm:w-auto px-4 py-2 text-white bg-red-600 rounded-lg hover:bg-red-700"
                            >
                                Confirm Update
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ImprovedPreferenceModal;