import React, { useState, useEffect } from 'react';
import { CreditCard, AlertCircle } from 'lucide-react';

const SubscriptionStatistics = () => {
    const [statistics, setStatistics] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchStatistics = async () => {
            try {
                setLoading(true);
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/Admin/subscription-statistics`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch subscription statistics');
                }

                const data = await response.json();
                setStatistics(data);
                setError(null);
            } catch (err) {
                setError(err.message);
                console.error('Error fetching subscription statistics:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchStatistics();
        // Refresh every 5 minutes
        const interval = setInterval(fetchStatistics, 300000);
        return () => clearInterval(interval);
    }, []);

    if (loading) {
        return (
            <div className="flex justify-center p-6">
                <div className="animate-spin h-8 w-8 border-4 border-blue-500 rounded-full border-t-transparent"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="bg-red-50 text-red-600 p-4 rounded-lg flex items-center">
                <AlertCircle className="h-5 w-5 mr-2" />
                <span>Error loading subscription statistics: {error}</span>
            </div>
        );
    }

    if (!statistics || !statistics.activeSubscriptions) {
        return null;
    }

    // Define colors for different subscription tiers
    const getTierColor = (tier) => {
        const tierColors = {
            'Free': 'gray',
            'Basic': 'blue',
            'Pro': 'purple'
        };
        return tierColors[tier] || 'green';
    };

    return (
        <div className="mt-8">
            <h2 className="text-xl font-semibold mb-4">Subscription Statistics</h2>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                <div className="bg-white rounded-lg shadow p-4 border border-gray-100 md:col-span-1">
                    <div className="flex items-center">
                        <div className="rounded-full bg-indigo-100 p-3 mr-4">
                            <CreditCard className="h-6 w-6 text-indigo-600" />
                        </div>
                        <div>
                            <p className="text-sm text-gray-500">Active Subscriptions</p>
                            <p className="text-2xl font-bold">{statistics.totalActiveSubscriptions}</p>
                        </div>
                    </div>
                </div>

                <div className="bg-white rounded-lg shadow p-4 border border-gray-100 md:col-span-3">
                    <h3 className="text-lg font-medium mb-3">Subscription by Plan</h3>
                    <div className="space-y-4">
                        {statistics.activeSubscriptions.map((plan) => (
                            <div key={plan.planId} className="flex items-center">
                                <div className="w-24">
                                    <span className={`px-2 py-1 rounded text-xs font-medium bg-${getTierColor(plan.planName)}-100 text-${getTierColor(plan.planName)}-800`}>
                                        {plan.planName}
                                    </span>
                                </div>
                                <div className="flex-1 ml-2">
                                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                                        <div
                                            className={`bg-${getTierColor(plan.planName)}-500 h-2.5 rounded-full`}
                                            style={{ width: `${(plan.count / statistics.totalActiveSubscriptions) * 100}%` }}
                                        ></div>
                                    </div>
                                </div>
                                <div className="ml-4 min-w-[60px] text-right">{plan.count}</div>
                                <div className="ml-2 min-w-[60px] text-right text-gray-500 text-sm">
                                    {Math.round((plan.count / statistics.totalActiveSubscriptions) * 100)}%
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubscriptionStatistics;