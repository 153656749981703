import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { AlertCircle } from "lucide-react";
import axios from "axios";
import _ from "lodash";
import Navbar from "./NavMenu";
import Footer from "./Footer";
import LocationDropdown from "./Helpers/LocationDropdown";

const PropertySearchPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useState({
        city: "",
        priceRangeMin: "",
        priceRangeMax: "",
        propertyType: "",
        distanceRadius: "",
        minBedrooms: "",
        maxBedrooms: ""
    });
    const [dropdownOptions, setDropdownOptions] = useState({
        PriceRange: [],
        PropertyType: [],
        DistanceRadius: [],
        Bedrooms: []
    });
    const [validationErrors, setValidationErrors] = useState({});
    const [showValidationAlert, setShowValidationAlert] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Fetch initial location from query params
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const cityParam = params.get("location");
        if (cityParam) {
            setSearchParams(prev => ({ ...prev, city: cityParam }));
        }

        // Fetch dropdown options
        fetchDropdownOptions();
    }, [location]);

    // Fetch dropdown options
    const fetchDropdownOptions = async () => {
        try {
            // Replace with your actual API endpoint
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/preferences/dropdown-options`,
            );
            setDropdownOptions(response.data);
        } catch (error) {
            console.error("Error fetching dropdown options:", error);
            // Set default options if API fails
            setDropdownOptions({
                PriceRange: [300, 400, 500, 600, 700, 800, 900, 1000, 1200, 1500, 2000, 2500, 3000, 4000, 5000],
                PropertyType: ["Flat", "House", "Studio", "Room"],
                DistanceRadius: [0, 1, 3, 5, 10, 15, 20, 30, 40],
                Bedrooms: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
            });
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSearchParams(prev => ({ ...prev, [name]: value }));

        // Clear validation error when field is updated
        if (validationErrors[name]) {
            setValidationErrors(prev => ({ ...prev, [name]: null }));
        }
    };

    const handleLocationSelect = (suggestion) => {
        const cityName = suggestion.structured_formatting?.main_text || suggestion.name;
        setSearchParams(prev => ({ ...prev, city: cityName }));

        // Clear validation error when field is updated
        if (validationErrors.city) {
            setValidationErrors(prev => ({ ...prev, city: null }));
        }
    };

    const validateForm = () => {
        const errors = {};
        let isValid = true;

        // Required fields
        const requiredFields = {
            city: "Town or PostCode",
            priceRangeMin: "Min Price Monthly",
            priceRangeMax: "Max Price Monthly",
            propertyType: "Property Type",
            distanceRadius: "Distance Radius",
            minBedrooms: "Min Bedrooms",
            maxBedrooms: "Max Bedrooms"
        };

        // Check required fields
        Object.entries(requiredFields).forEach(([field, label]) => {
            if (!searchParams[field]) {
                errors[field] = `${label} is required`;
                isValid = false;
            }
        });

        // Additional validation rules
        if (searchParams.priceRangeMin && searchParams.priceRangeMax &&
            Number(searchParams.priceRangeMin) > Number(searchParams.priceRangeMax)) {
            errors.priceRangeMax = "Max price should be greater than min price";
            isValid = false;
        }

        if (searchParams.minBedrooms && searchParams.maxBedrooms &&
            Number(searchParams.minBedrooms) > Number(searchParams.maxBedrooms)) {
            errors.maxBedrooms = "Max bedrooms should be greater than min bedrooms";
            isValid = false;
        }

        setValidationErrors(errors);
        setShowValidationAlert(!isValid);
        return isValid;
    };

    const handleSubmit = async () => {
        if (validateForm()) {
            setIsSubmitting(true);
            try {
                // Parse and clean values
                const formattedParams = {
                    ...searchParams,
                    priceRangeMin: parseInt(searchParams.priceRangeMin.toString().replace(/[^0-9]/g, '')),
                    priceRangeMax: parseInt(searchParams.priceRangeMax.toString().replace(/[^0-9]/g, '')),
                    minBedrooms: searchParams.minBedrooms === "Studio" ? 0 : parseInt(searchParams.minBedrooms),
                    maxBedrooms: parseInt(searchParams.maxBedrooms),
                    distanceRadius: parseInt(searchParams.distanceRadius.toString().replace(/[^0-9]/g, ''))
                };

                const response = await axios.post(
                    `${process.env.REACT_APP_BACKEND_URL}/api/PreferenceAnalysis/public/analyze`,
                    formattedParams
                );

                if (response.data && response.data.preferenceId) {
                    navigate(`/property-analysis?id=${response.data.preferenceId}`);
                } else {
                    navigate("/dashboard");
                }
            } catch (error) {
                console.error("Error submitting preferences:", error);
                setShowValidationAlert(true);
            } finally {
                setIsSubmitting(false);
            }
        }
    };

    const getInputClasses = (fieldName) => {
        return `w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent
        ${validationErrors[fieldName]
            ? 'border-red-500 bg-red-50'
            : 'border-gray-300'}`;
    };

    return (
        <>
            <Helmet>
                <title>Set Your Property Preferences | Dwellio</title>
                <meta name="description" content="Customize your property alert preferences to find your perfect rental property." />
            </Helmet>

            <Navbar />

            <div className="min-h-[calc(100vh-350px)] bg-gray-100 pt-0 pb-4">
                <div className="w-full">
                    <div className="bg-white rounded-lg shadow-md mx-auto max-w-md mb-4">
                        <div className="p-3">
                            <h1 className="text-lg font-bold text-gray-900 mb-2 dwellio-mobile-heading">Tell us about
                                your ideal property</h1>

                            {showValidationAlert && (
                                <div
                                    className="mb-2 p-2 bg-red-50 text-red-600 rounded-md flex items-center gap-1.5 dwellio-mobile-alert">
                                    <AlertCircle className="h-4 w-4 flex-shrink-0"/>
                                    <p className="text-xs">Please fill in all required fields correctly</p>
                                </div>
                            )}

                            <form className="space-y-2">
                                <div className="dwellio-mobile-form-group">
                                    <label
                                        className="block text-sm font-medium text-gray-700 mb-1 dwellio-mobile-label">
                                        Town or PostCode <span className="text-red-500">*</span>
                                    </label>
                                    <LocationDropdown onLocationSelect={handleLocationSelect}
                                                      initialLocation={searchParams.city}
                                                      className="dwellio-mobile-input w-full"/>
                                    {validationErrors.city && (
                                        <p className="mt-0.5 text-xs text-red-500 dwellio-mobile-error">{validationErrors.city}</p>
                                    )}
                                </div>

                                <div className="dwellio-mobile-form-group flex items-center gap-2">
                                    <div className="flex-1">
                                        <label
                                            className="block text-sm font-medium text-gray-700 mb-1 dwellio-mobile-label">
                                            Min Price Monthly <span className="text-red-500">*</span>
                                        </label>
                                        <select
                                            name="priceRangeMin"
                                            value={searchParams.priceRangeMin || ""}
                                            onChange={handleChange}
                                            className={getInputClasses('priceRangeMin')}
                                        >
                                            <option value="">Min</option>
                                            {dropdownOptions.PriceRange?.map((option, index) => (
                                                <option key={index} value={option}>{`${option}`}</option>
                                            ))}
                                        </select>
                                        {validationErrors.priceRangeMin && (
                                            <p className="mt-0.5 text-xs text-red-500 dwellio-mobile-error">{validationErrors.priceRangeMin}</p>
                                        )}
                                    </div>
                                    <div className="flex items-center self-end pb-2">
                                        <span className="text-gray-500 font-medium">-</span>
                                    </div>
                                    <div className="flex-1">
                                        <label
                                            className="block text-sm font-medium text-gray-700 mb-1 dwellio-mobile-label">
                                            Max Price Monthly <span className="text-red-500">*</span>
                                        </label>
                                        <select
                                            name="priceRangeMax"
                                            value={searchParams.priceRangeMax || ""}
                                            onChange={handleChange}
                                            className={getInputClasses('priceRangeMax')}
                                        >
                                            <option value="">Max</option>
                                            {dropdownOptions.PriceRange?.map((option, index) => (
                                                <option key={index} value={option}>{`${option}`}</option>
                                            ))}
                                        </select>
                                        {validationErrors.priceRangeMax && (
                                            <p className="mt-0.5 text-xs text-red-500 dwellio-mobile-error">{validationErrors.priceRangeMax}</p>
                                        )}
                                    </div>
                                </div>

                                <div className="dwellio-mobile-form-group flex items-center gap-2">
                                    <div className="flex-1">
                                        <label
                                            className="block text-sm font-medium text-gray-700 mb-1 dwellio-mobile-label">
                                            Min Bedrooms <span className="text-red-500">*</span>
                                        </label>
                                        <select
                                            name="minBedrooms"
                                            value={searchParams.minBedrooms === 0 ? "Studio" : searchParams.minBedrooms || ""}
                                            onChange={handleChange}
                                            className={getInputClasses('minBedrooms')}
                                        >
                                            <option value="">Min</option>
                                            {dropdownOptions.Bedrooms?.map((option, index) => (
                                                <option key={index} value={option === 0 ? "Studio" : option}>
                                                    {option === 0 ? "Studio" : option}
                                                </option>
                                            ))}
                                        </select>
                                        {validationErrors.minBedrooms && (
                                            <p className="mt-0.5 text-xs text-red-500 dwellio-mobile-error">{validationErrors.minBedrooms}</p>
                                        )}
                                    </div>
                                    <div className="flex items-center self-end pb-2">
                                        <span className="text-gray-500 font-medium">-</span>
                                    </div>
                                    <div className="flex-1">
                                        <label
                                            className="block text-sm font-medium text-gray-700 mb-1 dwellio-mobile-label">
                                            Max Bedrooms <span className="text-red-500">*</span>
                                        </label>
                                        <select
                                            name="maxBedrooms"
                                            value={searchParams.maxBedrooms || ""}
                                            onChange={handleChange}
                                            className={getInputClasses('maxBedrooms')}
                                        >
                                            <option value="">Max</option>
                                            {dropdownOptions.Bedrooms?.map((option, index) => (
                                                <option key={index} value={option}>{option}</option>
                                            ))}
                                        </select>
                                        {validationErrors.maxBedrooms && (
                                            <p className="mt-0.5 text-xs text-red-500 dwellio-mobile-error">{validationErrors.maxBedrooms}</p>
                                        )}
                                    </div>
                                </div>


                                <div className="dwellio-mobile-form-group">
                                    <label
                                        className="block text-sm font-medium text-gray-700 mb-1 dwellio-mobile-label">
                                        Property Type <span className="text-red-500">*</span>
                                    </label>
                                    <select
                                        name="propertyType"
                                        value={searchParams.propertyType || ""}
                                        onChange={handleChange}
                                        className={getInputClasses('propertyType')}
                                    >
                                        <option value="">Select Property Type</option>
                                        {dropdownOptions.PropertyType?.map((option, index) => (
                                            <option key={index} value={option}>{option}</option>
                                        ))}
                                    </select>
                                    {validationErrors.propertyType && (
                                        <p className="mt-0.5 text-xs text-red-500 dwellio-mobile-error">{validationErrors.propertyType}</p>
                                    )}
                                </div>

                                <div className="dwellio-mobile-form-group">
                                    <label
                                        className="block text-sm font-medium text-gray-700 mb-1 dwellio-mobile-label">
                                        Distance Radius <span className="text-red-500">*</span>
                                    </label>
                                    <select
                                        name="distanceRadius"
                                        value={searchParams.distanceRadius || ""}
                                        onChange={handleChange}
                                        className={getInputClasses('distanceRadius')}
                                    >
                                        <option value="">Select Distance</option>
                                        {dropdownOptions.DistanceRadius?.map((option, index) => {
                                            const isFormatted = typeof option === 'string' && option.includes('mile');
                                            return (
                                                <option key={index} value={option}>
                                                    {isFormatted ? option : `${option} ${option === 0 ? '(exact location)' : 'miles'}`}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    {validationErrors.distanceRadius && (
                                        <p className="mt-0.5 text-xs text-red-500 dwellio-mobile-error">{validationErrors.distanceRadius}</p>
                                    )}
                                </div>

                                <div className="w-full">
                                    <button
                                        type="button"
                                        onClick={handleSubmit}
                                        disabled={isSubmitting}
                                        className="w-full px-6 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed text-sm dwellio-mobile-large-button"
                                    >
                                        {isSubmitting ? (
                                            <>
                                                <div
                                                    className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin mr-1"></div>
                                                Processing...
                                            </>
                                        ) : "Create Property Alert"}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    );
};

export default PropertySearchPage;