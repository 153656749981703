import React, { useState, useEffect } from 'react';
import {Users, TrendingUp, Calendar, AlertCircle} from 'lucide-react';

const UserStatistics = () => {
    const [statistics, setStatistics] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchStatistics = async () => {
            try {
                setLoading(true);
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/Admin/user-statistics`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch user statistics');
                }

                const data = await response.json();
                setStatistics(data);
                setError(null);
            } catch (err) {
                setError(err.message);
                console.error('Error fetching user statistics:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchStatistics();
        // Refresh every 5 minutes
        const interval = setInterval(fetchStatistics, 300000);
        return () => clearInterval(interval);
    }, []);

    if (loading) {
        return (
            <div className="flex justify-center p-6">
                <div className="animate-spin h-8 w-8 border-4 border-blue-500 rounded-full border-t-transparent"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="bg-red-50 text-red-600 p-4 rounded-lg flex items-center">
                <AlertCircle className="h-5 w-5 mr-2" />
                <span>Error loading user statistics: {error}</span>
            </div>
        );
    }

    if (!statistics) {
        return null;
    }

    return (
        <div>
            <h2 className="text-xl font-semibold mb-4">User Statistics</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className="bg-white rounded-lg shadow p-4 border border-gray-100">
                    <div className="flex items-center">
                        <div className="rounded-full bg-blue-100 p-3 mr-4">
                            <Users className="h-6 w-6 text-blue-600" />
                        </div>
                        <div>
                            <p className="text-sm text-gray-500">Total Users</p>
                            <p className="text-2xl font-bold">{statistics.totalUsers}</p>
                        </div>
                    </div>
                </div>

                <div className="bg-white rounded-lg shadow p-4 border border-gray-100">
                    <div className="flex items-center">
                        <div className="rounded-full bg-green-100 p-3 mr-4">
                            <Calendar className="h-6 w-6 text-green-600" />
                        </div>
                        <div>
                            <p className="text-sm text-gray-500">Registered Today</p>
                            <p className="text-2xl font-bold">{statistics.todayRegistrations}</p>
                        </div>
                    </div>
                </div>

                <div className="bg-white rounded-lg shadow p-4 border border-gray-100">
                    <div className="flex items-center">
                        <div className="rounded-full bg-purple-100 p-3 mr-4">
                            <TrendingUp className="h-6 w-6 text-purple-600" />
                        </div>
                        <div>
                            <p className="text-sm text-gray-500">Last 7 Days</p>
                            <p className="text-2xl font-bold">{statistics.last7DaysRegistrations}</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* User Registration Chart */}
            {statistics.usersByDay && statistics.usersByDay.length > 0 && (
                <div className="mt-6">
                    <h3 className="text-lg font-medium mb-2">Registration Trend (Last 7 Days)</h3>
                    <div className="h-64 bg-white p-4 rounded-lg shadow border border-gray-100">
                        <div className="flex h-full items-end space-x-2">
                            {statistics.usersByDay.map((day) => {
                                const percentage = Math.min(100, (day.count / Math.max(...statistics.usersByDay.map(d => d.count))) * 100);
                                return (
                                    <div key={day.date} className="flex-1 flex flex-col items-center">
                                        <div
                                            className="w-full bg-blue-500 rounded-t"
                                            style={{ height: `${percentage}%` }}
                                        ></div>
                                        <div className="text-xs mt-1 text-gray-600">
                                            {new Date(day.date).toLocaleDateString('en-US', { weekday: 'short' })}
                                        </div>
                                        <div className="text-xs font-medium">{day.count}</div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserStatistics;