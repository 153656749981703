import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import Navbar from './NavMenu';
import Footer from './Footer';
import { Home, Search, ArrowLeft } from 'lucide-react';

const NotFound = () => {
    const navigate = useNavigate();

    const commonLinks = [
        { name: 'Home', path: '/', icon: Home },
        { name: 'Property Search', path: '/how-it-works', icon: Search },
        { name: 'About Us', path: '/about-us', icon: ArrowLeft }
    ];

    return (
        <>
            <Helmet>
                <title>Page Not Found - Dwellio</title>
                <meta
                    name="description"
                    content="Sorry, we couldn't find the page you're looking for. Please check the URL or navigate back to our homepage."
                />
            </Helmet>
            <Navbar />

            <div className="min-h-[80vh] flex items-center justify-center bg-gradient-to-b from-gray-50 to-white py-20">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="text-center">
                        {/* Large 404 Text */}
                        <h1 className="text-[200px] font-bold text-gray-900 leading-none mb-8 animate-pulse">
                            404
                        </h1>

                        {/* Main Content */}
                        <h2 className="text-5xl font-bold text-gray-900 mb-6">
                            Page Not Found
                        </h2>

                        <p className="text-2xl text-gray-600 mb-12 max-w-2xl mx-auto">
                            Sorry, we couldn't find the page you're looking for. It might have been moved or doesn't exist.
                        </p>

                        {/* Quick Navigation */}
                        <div className="bg-white rounded-xl shadow-lg p-8 mb-12 max-w-3xl mx-auto">
                            <h3 className="text-2xl font-semibold text-gray-900 mb-8">
                                Popular Destinations
                            </h3>
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                                {commonLinks.map((link) => (
                                    <button
                                        key={link.path}
                                        onClick={() => navigate(link.path)}
                                        className="flex items-center justify-center space-x-3 p-4 rounded-xl hover:bg-blue-50 transition-colors group"
                                    >
                                        <link.icon className="h-6 w-6 text-blue-600 group-hover:scale-110 transition-transform" />
                                        <span className="text-lg text-gray-600">{link.name}</span>
                                    </button>
                                ))}
                            </div>
                        </div>

                        {/* Primary CTA */}
                        <button
                            onClick={() => navigate('/')}
                            className="px-12 py-4 bg-blue-600 text-white text-xl rounded-xl hover:bg-blue-700 transition-all hover:scale-105 inline-flex items-center shadow-lg"
                        >
                            <Home className="h-6 w-6 mr-3" />
                            Back to Homepage
                        </button>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default NotFound;