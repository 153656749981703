import React from 'react';
import { Helmet } from 'react-helmet-async';
import Navbar from './NavMenu';
import Footer from './Footer';
import {
    Users,
    Target,
    Shield,
    Clock,
    MessageSquare,
    Heart,
    Building2,
    Key
} from 'lucide-react';

const AboutUs = () => {
    const mission = {
        title: "Our Mission",
        description: "At Dwellio, we're transforming the rental search experience across the UK. We believe everyone deserves a fair chance at finding their perfect home, without the stress of constantly monitoring property websites or missing out on opportunities.",
        points: [
            {
                icon: Clock,
                title: "Save Time",
                description: "Eliminate hours of manual searching with our automated alerts"
            },
            {
                icon: Target,
                title: "Be First",
                description: "Get notified about new properties before others"
            },
            {
                icon: Shield,
                title: "Stay Informed",
                description: "Never miss a relevant property listing again"
            }
        ]
    };

    const values = [
        {
            icon: Users,
            title: "Customer First",
            description: "Everything we do is focused on helping our users find their ideal homes faster and easier."
        },
        {
            icon: Building2,
            title: "Market Innovation",
            description: "We continuously improve our technology to provide the most efficient property alerts in the UK."
        },
        {
            icon: MessageSquare,
            title: "Clear Communication",
            description: "We believe in transparent, honest communication with our users at every step."
        },
        {
            icon: Heart,
            title: "Community Impact",
            description: "We're committed to making the rental market more accessible and fair for everyone."
        }
    ];

    const story = {
        title: "Our Story",
        content: [
            "Dwellio was born from a simple observation: the rental market moves too fast for traditional search methods. Our founders experienced firsthand the frustration of missing out on perfect properties because they weren't quick enough to respond.",
            "We built Dwellio to solve this problem, creating a platform that helps renters stay ahead in a competitive market. Since our launch, we've helped hundreds of people across the UK find their ideal homes faster and with less stress.",
            "Today, we're proud to be one of the UK's leading rental alert services, continuously innovating to make property searching easier and more efficient for everyone."
        ]
    };

    const statistics = [
        {
            number: "3min",
            label: "Average alert time"
        },
        {
            number: "100+",
            label: "Active users"
        },
        {
            number: "24/7",
            label: "Property monitoring"
        },
        {
            number: "98%",
            label: "User satisfaction"
        }
    ];

    return (
        <>
            <Helmet>
                <title>About Dwellio - UK's Leading Rental Alert Service</title>
                <meta name="description" content="Learn about Dwellio's mission to transform the UK rental market through innovative property alerts. Discover how we're helping hundreds find their perfect homes faster." />
                <link rel="canonical" href="https://dwellio.co.uk/about-us" />
                <meta property="og:title" content="About Dwellio - UK's Leading Rental Alert Service" />
                <meta property="og:description" content="Learn about Dwellio's mission to transform the UK rental market through innovative property alerts." />
                <meta property="og:url" content="https://dwellio.co.uk/about-us" />
                <meta property="og:type" content="website" />
            </Helmet>
            <Navbar />

            <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
                {/* Hero Section */}
                <div className="relative bg-blue-600 text-white py-20">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
                        <h1 className="text-4xl md:text-5xl font-bold mb-6">
                            Transforming Rental Search in the UK
                        </h1>
                        <p className="text-xl md:text-2xl text-blue-100 max-w-3xl mx-auto">
                            We're on a mission to make finding your perfect rental property faster,
                            easier, and fairer for everyone.
                        </p>
                    </div>
                </div>

                {/* Mission Section */}
                <div className="py-16">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="text-center mb-12">
                            <h2 className="text-3xl font-bold text-gray-900 mb-4">
                                {mission.title}
                            </h2>
                            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                                {mission.description}
                            </p>
                        </div>

                        <div className="grid md:grid-cols-3 gap-8">
                            {mission.points.map((point, index) => (
                                <div key={index} className="bg-white p-6 rounded-lg shadow-sm text-center">
                                    <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mx-auto mb-4">
                                        <point.icon className="h-6 w-6 text-blue-600" />
                                    </div>
                                    <h3 className="text-xl font-semibold mb-2">{point.title}</h3>
                                    <p className="text-gray-600">{point.description}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Story Section */}
                <div className="bg-gray-50 py-16">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="max-w-3xl mx-auto text-center">
                            <h2 className="text-3xl font-bold text-gray-900 mb-8">
                                {story.title}
                            </h2>
                            {story.content.map((paragraph, index) => (
                                <p key={index} className="text-gray-600 mb-4">
                                    {paragraph}
                                </p>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Statistics Section */}
                <div className="py-16 bg-white">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
                            {statistics.map((stat, index) => (
                                <div key={index} className="text-center">
                                    <div className="text-4xl font-bold text-blue-600 mb-2">
                                        {stat.number}
                                    </div>
                                    <div className="text-gray-600">{stat.label}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Values Section */}
                <div className="bg-gray-50 py-16">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="text-center mb-12">
                            <h2 className="text-3xl font-bold text-gray-900 mb-4">
                                Our Values
                            </h2>
                            <p className="text-xl text-gray-600">
                                The principles that guide everything we do
                            </p>
                        </div>

                        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
                            {values.map((value, index) => (
                                <div key={index} className="bg-white p-6 rounded-lg shadow-sm">
                                    <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mb-4">
                                        <value.icon className="h-6 w-6 text-blue-600" />
                                    </div>
                                    <h3 className="text-xl font-semibold mb-2">{value.title}</h3>
                                    <p className="text-gray-600">{value.description}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {/* CTA Section */}
                <div className="bg-blue-600 py-16">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="text-center text-white">
                            <h2 className="text-3xl font-bold mb-4">
                                Join Our Growing Community
                            </h2>
                            <p className="text-xl text-blue-100 mb-8">
                                Start finding your perfect rental property today
                            </p>
                            <a
                                href="/subscriptions"
                                className="inline-block bg-white text-blue-600 px-8 py-3 rounded-lg font-medium hover:bg-gray-100 transition-colors"
                            >
                                Get Started
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default AboutUs;