import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Loader2 } from 'lucide-react';
import Navbar from '../NavMenu';
import Footer from '../Footer';

const PropertyRedirect = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchAndRedirect = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/api/listings/redirect/${id}`
                );

                if (response.data.url) {
                    window.location.href = response.data.url;
                } else {
                    setError('Property not found');
                    setTimeout(() => navigate('/dashboard'), 3000);
                }
            } catch (error) {
                setError(error.response?.data?.message || 'Unable to redirect to property');
                setTimeout(() => navigate('/dashboard'), 3000);
            }
        };

        fetchAndRedirect();
    }, [id, navigate]);

    return (
        <>
            <Navbar />
            <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
                <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                    <div className="flex flex-col items-center justify-center">
                        {error ? (
                            <div className="text-center">
                                <h1 className="text-2xl font-bold text-red-600 mb-2">{error}</h1>
                                <p className="text-gray-600">Redirecting to dashboard...</p>
                            </div>
                        ) : (
                            <div className="text-center">
                                <Loader2 className="w-8 h-8 animate-spin mb-4 mx-auto text-blue-600" />
                                <h1 className="text-2xl font-bold text-gray-900 mb-2">Redirecting to Property</h1>
                                <p className="text-gray-600">Please wait while we redirect you to the property listing...</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default PropertyRedirect;