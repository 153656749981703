import React, {useState} from 'react';
import {AlertCircle, Check, Edit3, Info, Loader2, Pause, Play, Trash2, XCircle} from 'lucide-react';
import EmptyState from "./EmptyState";
import PreferenceCard from "./MobileView/PreferenceCard";
import ConfirmationDialog from "./Helpers/ConfirmationDialog";
import AnalysisSection from './AnalysisSection';
import MobileAnalysisSection from './MobileView/MobileAnalysisSection';

const PreferencesTable = ({preferences, onEdit, onDelete, onPause, onAddPreference, onDismissAnalysis}) => {
    const [confirmationDialog, setConfirmationDialog] = useState({
        isOpen: false,
        type: null,
        preferenceId: null,
        currentStatus: null
    });

    const getConfirmationMessage = (type, preference) => {
        switch (type) {
            case 'delete':
                return {
                    title: "Delete Alert",
                    message: `Are you sure you want to delete your alert for ${preference.city}? This action cannot be undone.`
                };
            case 'pause':
                return {
                    title: "Pause Alert",
                    message: `Are you sure you want to pause your alert for ${preference.city}? You won't receive notifications for this alert while it's paused.`
                };
            case 'resume':
                return {
                    title: "Resume Alert",
                    message: `Are you sure you want to resume your alert for ${preference.city}? You'll start receiving notifications for this alert again.`
                };
            default:
                return {title: "", message: ""};
        }
    };

    const handleConfirmAction = () => {
        const {type, preferenceId, currentStatus} = confirmationDialog;

        if (type === 'delete') {
            onDelete(preferenceId);
        } else if (type === 'pause' || type === 'resume') {
            onPause(preferenceId, !currentStatus);
        }

        setConfirmationDialog({
            isOpen: false,
            type: null,
            preferenceId: null,
            currentStatus: null
        });
    };

    const handleDeleteClick = (preference) => {
        setConfirmationDialog({
            isOpen: true,
            type: 'delete',
            preferenceId: preference.id,
            currentStatus: null
        });
    };

    const handlePauseResumeClick = (preference) => {
        setConfirmationDialog({
            isOpen: true,
            type: preference.isActive ? 'pause' : 'resume',
            preferenceId: preference.id,
            currentStatus: preference.isActive
        });
    };

    const getRowStyles = (pref) => {
        if (pref.isNew && (!pref.analysis || pref.analysisStatus === 'queued')) {
            return 'border-2 border-blue-200 bg-blue-50/30';
        }
        if (pref.analysis) {
            switch (pref.analysis.riskLevel) {
                case 'high':
                    return 'border-2 border-red-200 bg-red-50/30';
                case 'medium':
                    return 'border-2 border-yellow-200 bg-yellow-50/30';
                case 'low':
                    return 'border-2 border-green-200 bg-green-50/30';
                default:
                    return '';
            }
        }
        return '';
    };

    return (
        <div className="bg-white rounded-lg shadow-sm p-6 relative space-y-4 mb-8">
            <div className="mb-6">
                <h2 className="text-2xl font-semibold text-gray-900">Your Alerts</h2>
                <p className="text-gray-600 mt-1">Manage your property search criteria</p>
            </div>

            {/* Desktop View */}
            <div className="hidden md:block">
                <table className="w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                    <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Location</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Price Range</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Bedrooms</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Listings Matched</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                    </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                    {!preferences?.length ? (
                        <tr>
                            <td colSpan="7" className="px-6 py-4">
                                <EmptyState type="preferences" onAction={onAddPreference}/>
                            </td>
                        </tr>
                    ) : (
                        preferences.map((pref) => (
                            <React.Fragment key={pref.id}>
                                <tr className={`transition-all duration-200 ${getRowStyles(pref)}`}>
                                    <td className="px-6 py-4">
                                        {pref.isActive ? (
                                            <div className="space-y-1">
                                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                                    Active
                                                </span>
                                                {pref.isNew && (
                                                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                                                        <Loader2 className="h-3 w-3 animate-spin mr-1"/>
                                                        Analyzing
                                                    </span>
                                                )}
                                            </div>
                                        ) : (
                                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                                Paused
                                            </span>
                                        )}
                                    </td>
                                    <td className="px-6 py-4 text-sm text-gray-900">{pref.city}</td>
                                    <td className="px-6 py-4 text-sm text-gray-900">£{pref.priceRangeMin} - £{pref.priceRangeMax}</td>
                                    <td className="px-6 py-4 text-sm text-gray-900">{pref.propertyType}</td>
                                    <td className="px-6 py-4 text-sm text-gray-900">{pref.minBedrooms} - {pref.maxBedrooms}</td>
                                    <td className="px-6 py-4 text-sm text-gray-900">{pref.listingsMatched}</td>
                                    <td className="px-6 py-4 text-sm text-gray-500">
                                        <div className="flex space-x-3">
                                            <button onClick={() => onEdit(pref.id)} className="text-blue-600 hover:text-blue-800">
                                                <Edit3 className="h-4 w-4"/>
                                            </button>
                                            <button onClick={() => handlePauseResumeClick(pref)} className="text-gray-600 hover:text-gray-800">
                                                {pref.isActive ? <Pause className="h-4 w-4"/> : <Play className="h-4 w-4"/>}
                                            </button>
                                            <button onClick={() => handleDeleteClick(pref)} className="text-red-600 hover:text-red-800">
                                                <Trash2 className="h-4 w-4"/>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="7" className="px-6 py-2">
                                        <AnalysisSection
                                            preference={pref}
                                            onDismiss={onDismissAnalysis}
                                        />
                                    </td>
                                </tr>
                            </React.Fragment>
                        ))
                    )}
                    </tbody>
                </table>
            </div>

            {/* Mobile View */}
            <div className="md:hidden space-y-4">
                {!preferences?.length ? (
                    <EmptyState type="preferences" onAction={onAddPreference}/>
                ) : (
                    preferences.map(pref => (
                        <React.Fragment key={pref.id}>
                            <PreferenceCard
                                pref={pref}
                                onEdit={onEdit}
                                onDelete={() => handleDeleteClick(pref)}
                                onPause={() => handlePauseResumeClick(pref)}
                            />
                            <MobileAnalysisSection
                                preference={pref}
                                onDismiss={onDismissAnalysis}
                            />
                        </React.Fragment>
                    ))
                )}
            </div>

            {/* Confirmation Dialog */}
            {confirmationDialog.isOpen && (
                <ConfirmationDialog
                    isOpen={confirmationDialog.isOpen}
                    onClose={() => setConfirmationDialog({
                        isOpen: false,
                        type: null,
                        preferenceId: null,
                        currentStatus: null
                    })}
                    onConfirm={handleConfirmAction}
                    {...getConfirmationMessage(
                        confirmationDialog.type,
                        preferences.find(p => p.id === confirmationDialog.preferenceId)
                    )}
                    actionType={confirmationDialog.type}
                />
            )}
        </div>
    );
};

export default PreferencesTable;