import React, { useState, useEffect } from 'react';
import { AlertCircle, CheckCircle, PlayCircle, StopCircle, Users, TrendingUp, Calendar, CreditCard } from 'lucide-react';
import BackgroundTasksDashboard from './BackgroundTaskDashboard';
import UserStatistics from './UserStatistics';
import SubscriptionStatistics from './SubscriptionStatistics';
import RecentActivities from './RecentActivities';
import UserManagement from './UserManagement';

const AdminDashboard = () => {
    const [activeTab, setActiveTab] = useState('overview');

    return (
        <div className="min-h-screen bg-gray-50">
            <div className="container mx-auto px-4 py-6">
                <h1 className="text-3xl font-bold mb-6">Admin Dashboard</h1>

                {/* Navigation Tabs */}
                <div className="flex mb-6 border-b border-gray-200">
                    <button
                        onClick={() => setActiveTab('overview')}
                        className={`px-4 py-2 font-medium ${activeTab === 'overview' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-500 hover:text-gray-700'}`}
                    >
                        Overview
                    </button>
                    <button
                        onClick={() => setActiveTab('tasks')}
                        className={`px-4 py-2 font-medium ${activeTab === 'tasks' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-500 hover:text-gray-700'}`}
                    >
                        Background Tasks
                    </button>
                    <button
                        onClick={() => setActiveTab('users')}
                        className={`px-4 py-2 font-medium ${activeTab === 'users' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-500 hover:text-gray-700'}`}
                    >
                        User Management
                    </button>
                </div>

                {/* Tab Content */}
                <div className="bg-white rounded-lg shadow-sm p-6">
                    {activeTab === 'overview' && (
                        <div className="space-y-6">
                            <UserStatistics />
                            <SubscriptionStatistics />
                            <RecentActivities />
                        </div>
                    )}

                    {activeTab === 'tasks' && (
                        <BackgroundTasksDashboard />
                    )}

                    {activeTab === 'users' && (
                        <UserManagement />
                    )}
                </div>
            </div>
        </div>
    );
};

export default AdminDashboard;