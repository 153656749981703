import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Context/AuthProvider';
import Navbar from '../NavMenu';
import Footer from '../Footer';
import { CheckCircle } from 'lucide-react';

const EmailConfirmed = () => {
    const navigate = useNavigate();
    const { isAuthenticated, refreshAuthState } = useAuth();
    const [countdown, setCountdown] = useState(5);

    useEffect(() => {
        // Refresh the auth state to get a new token with updated claims
        if (isAuthenticated) {
            refreshAuthState();
        }

        // Set up countdown to redirect
        const timer = setInterval(() => {
            setCountdown(prev => {
                if (prev <= 1) {
                    clearInterval(timer);
                    navigate('/dashboard');
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, [navigate, isAuthenticated, refreshAuthState]);

    return (
        <>
            <Navbar />
            <div className="min-h-screen bg-gray-50 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-md w-full space-y-8 bg-white p-10 rounded-xl shadow-md">
                    <div className="flex flex-col items-center">
                        <div className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-green-100">
                            <CheckCircle className="h-10 w-10 text-green-600" />
                        </div>
                        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                            Email Confirmed!
                        </h2>
                        <p className="mt-2 text-center text-sm text-gray-600">
                            Your email has been successfully verified. You can now access all features.
                        </p>
                        <div className="mt-6">
                            <p className="text-sm text-gray-500">
                                Redirecting to dashboard in {countdown} seconds...
                            </p>
                            <button
                                onClick={() => navigate('/dashboard')}
                                className="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            >
                                Go to Dashboard Now
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default EmailConfirmed;