import React, { useState, useEffect, useRef } from 'react';
import { MapPin, X, Loader2 } from 'lucide-react';
import axios from 'axios';
import _ from 'lodash';

const LocationDropdown = ({ onLocationSelect, initialLocation = '' }) => {
    const [query, setQuery] = useState(initialLocation || '');
    const [suggestions, setSuggestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [focused, setFocused] = useState(false);
    const [activeIndex, setActiveIndex] = useState(-1);
    const inputRef = useRef(null);
    const dropdownRef = useRef(null);

    // Set initial query from props
    useEffect(() => {
        if (initialLocation && initialLocation !== query) {
            setQuery(initialLocation);
        }
    }, [initialLocation]);

    // Debounced API call
    const debouncedFetch = _.debounce(async (input) => {
        if (!input || input.length < 2) {
            setSuggestions([]);
            return;
        }

        setLoading(true);
        try {
            const response = await axios.get('/api/Places/autocomplete', {
                params: { input: input },
            });

            if (response.data.status === 'OK' && response.data.predictions) {
                // Show up to 8 results instead of limiting to fewer
                setSuggestions(response.data.predictions.slice(0, 8));
            } else {
                setSuggestions([]);
            }
        } catch (error) {
            console.error('Error fetching location suggestions:', error);
            setSuggestions([]);
        }
        setLoading(false);
    }, 300);

    useEffect(() => {
        debouncedFetch(query);
    }, [query]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setFocused(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleInputChange = (e) => {
        setQuery(e.target.value);
        setActiveIndex(-1);
    };

    const handleClear = () => {
        setQuery('');
        setFocused(true);
        inputRef.current.focus();
    };

    const handleSelect = (prediction) => {
        const locationName = prediction.structured_formatting.main_text;
        setQuery(locationName);
        setSuggestions([]);
        setFocused(false);
        onLocationSelect(prediction);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            setActiveIndex(prev =>
                prev < suggestions.length - 1 ? prev + 1 : prev
            );
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            setActiveIndex(prev => (prev > 0 ? prev - 1 : prev));
        } else if (e.key === 'Enter' && activeIndex >= 0) {
            e.preventDefault();
            handleSelect(suggestions[activeIndex]);
        } else if (e.key === 'Escape') {
            setFocused(false);
        }
    };

    return (
        <div className="relative w-full" ref={dropdownRef}>
            <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none">
                    <MapPin className="h-4 w-4 text-gray-400" />
                </div>

                <input
                    ref={inputRef}
                    type="text"
                    className="w-full pl-8 pr-8 py-2 bg-white text-gray-900 rounded-md border border-gray-300 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 focus:outline-none text-sm"
                    placeholder="Enter town, city or postcode"
                    value={query}
                    onChange={handleInputChange}
                    onFocus={() => setFocused(true)}
                    onKeyDown={handleKeyDown}
                    autoComplete="off"
                    aria-label="Location search"
                />

                {query && (
                    <button
                        type="button"
                        onClick={handleClear}
                        className="absolute inset-y-0 right-0 pr-2 flex items-center text-gray-400 hover:text-gray-600"
                        aria-label="Clear search"
                    >
                        <X className="h-4 w-4" />
                    </button>
                )}
            </div>

            {focused && (
                <div className="absolute left-0 w-full z-[100] mt-1" style={{ position: 'absolute', zIndex: 9999 }}>
                    <div className="bg-white rounded-md border border-gray-200 shadow-lg max-h-72 overflow-y-auto text-sm">
                        {loading ? (
                            <div className="flex justify-center items-center p-2">
                                <Loader2 className="h-4 w-4 text-blue-500 animate-spin" />
                                <span className="ml-2 text-gray-600 text-xs">Searching locations...</span>
                            </div>
                        ) : suggestions.length > 0 ? (
                            <ul className="py-1">
                                {suggestions.map((prediction, index) => (
                                    <li key={prediction.place_id}>
                                        <button
                                            type="button"
                                            className={`w-full text-left px-3 py-2 hover:bg-gray-100 ${
                                                activeIndex === index ? 'bg-blue-50' : ''
                                            }`}
                                            onClick={() => handleSelect(prediction)}
                                            onMouseEnter={() => setActiveIndex(index)}
                                        >
                                            <div className="font-medium text-gray-900 text-sm">{prediction.structured_formatting.main_text}</div>
                                            <div className="text-xs text-gray-600">{prediction.structured_formatting.secondary_text}</div>
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        ) : query.length >= 2 ? (
                            <div className="p-2 text-center text-gray-500 text-xs">
                                No locations found matching "{query}"
                            </div>
                        ) : query.length > 0 ? (
                            <div className="p-2 text-center text-gray-500 text-xs">
                                Please enter at least 2 characters
                            </div>
                        ) : (
                            <div className="p-2 text-center text-gray-500 text-xs">
                                Start typing to search for locations
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default LocationDropdown;