import React, { useState } from 'react';
import { X, MailCheck, AlertCircle, RefreshCw } from 'lucide-react';
import axios from 'axios';

const EmailVerificationModal = ({ show, onClose, email }) => {
    const [isResending, setIsResending] = useState(false);
    const [resendStatus, setResendStatus] = useState(null);

    if (!show) return null;

    const handleResendVerification = async () => {
        setIsResending(true);
        setResendStatus(null);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/auth/resend-verification`,
                { email },
            );

            setResendStatus({
                success: true,
                message: 'Verification email has been resent. Please check your inbox.'
            });
        } catch (error) {
            console.error('Error resending verification email:', error);
            setResendStatus({
                success: false,
                message: 'Failed to resend verification email. Please try again later.'
            });
        } finally {
            setIsResending(false);
        }
    };

    return (
        <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-xl max-w-md w-full p-6 relative animate-fade-in-up">
                {/* Close button */}
                {onClose && (
                    <button
                        onClick={onClose}
                        className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
                        aria-label="Close"
                    >
                        <X className="h-5 w-5" />
                    </button>
                )}

                {/* Icon */}
                <div className="flex justify-center mb-4">
                    <div className="bg-blue-50 p-3 rounded-full">
                        <MailCheck className="h-8 w-8 text-blue-500" />
                    </div>
                </div>

                {/* Title */}
                <h2 className="text-xl font-semibold text-center mb-2">
                    Verify Your Email
                </h2>

                {/* Description */}
                <p className="text-gray-600 text-center mb-6">
                    Please verify your email address to create alerts and receive notifications
                    about matching properties.
                </p>

                {/* Email display */}
                <div className="bg-gray-50 p-4 rounded-lg text-center mb-6">
                    <p className="text-sm text-gray-500 mb-1">Verification email sent to:</p>
                    <p className="font-medium">{email || 'your email address'}</p>
                </div>

                {/* Status message */}
                {resendStatus && (
                    <div className={`p-3 rounded-lg mb-4 flex items-start gap-2 ${
                        resendStatus.success ? 'bg-green-50 text-green-700' : 'bg-red-50 text-red-700'
                    }`}>
                        {resendStatus.success ? (
                            <MailCheck className="h-5 w-5 flex-shrink-0 mt-0.5" />
                        ) : (
                            <AlertCircle className="h-5 w-5 flex-shrink-0 mt-0.5" />
                        )}
                        <p className="text-sm">{resendStatus.message}</p>
                    </div>
                )}

                {/* Actions */}
                <div className="flex flex-col gap-3">
                    <button
                        onClick={handleResendVerification}
                        disabled={isResending}
                        className="w-full py-2 px-4 bg-blue-600 hover:bg-blue-700 text-white rounded-lg font-medium transition-colors flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                        {isResending ? (
                            <>
                                <RefreshCw className="h-4 w-4 mr-2 animate-spin" />
                                Sending...
                            </>
                        ) : (
                            'Resend Verification Email'
                        )}
                    </button>

                    {onClose && (
                        <button
                            onClick={onClose}
                            className="w-full py-2 px-4 border border-gray-300 text-gray-700 rounded-lg font-medium hover:bg-gray-50 transition-colors"
                        >
                            Remind Me Later
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default EmailVerificationModal;