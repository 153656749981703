import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../Context/AuthProvider';
import AuthUtils from '../Auth/AuthUtils';
import EmailVerificationModal from '../Auth/EmailVerificationModal';

export const ProtectedRoute = ({ children }) => {
    const { isAuthenticated } = useAuth();
    const location = useLocation();
    const [isEmailVerified, setIsEmailVerified] = useState(true); // Default to true to avoid flicker
    const [userEmail, setUserEmail] = useState('');
    const [isChecking, setIsChecking] = useState(true);
    const [showEmailModal, setShowEmailModal] = useState(false);

    useEffect(() => {
        const checkEmailVerification = async () => {
            if (isAuthenticated) {
                try {
                    setIsChecking(true);
                    const verified = await AuthUtils.isEmailVerified();
                    setIsEmailVerified(verified);

                    if (!verified) {
                        const email = await AuthUtils.getUserEmail();
                        setUserEmail(email);

                        // Only show the modal for protected routes that aren't the dashboard
                        if (location.pathname !== '/dashboard') {
                            setShowEmailModal(true);
                        }
                    }
                } catch (error) {
                    console.error("Error checking email verification:", error);
                    // Default to true on error to avoid blocking access
                    setIsEmailVerified(true);
                } finally {
                    setIsChecking(false);
                }
            }
        };

        checkEmailVerification();
    }, [isAuthenticated, location.pathname]);

    if (!isAuthenticated) {
        return <Navigate to="/login" state={{ redirectTo: location.pathname }} />;
    }

    // If we're still checking, show a loading spinner
    if (isChecking) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <div className="animate-spin h-8 w-8 border-4 border-blue-500 rounded-full border-t-transparent"></div>
            </div>
        );
    }

    // For dashboard, we'll always show it, but the component will handle
    // showing the verification banner internally
    if (location.pathname === '/dashboard') {
        return children;
    }

    // For other routes, we'll redirect unverified users to dashboard with a modal
    if (!isEmailVerified) {
        return (
            <>
                <EmailVerificationModal
                    show={showEmailModal}
                    email={userEmail}
                    onClose={() => {
                        setShowEmailModal(false);
                        // We'll return to dashboard when modal is closed
                    }}
                />
                <Navigate to="/dashboard" replace />
            </>
        );
    }

    // Email is verified, show the protected content
    return children;
};

export const PublicOnlyRoute = ({ children }) => {
    const { isAuthenticated } = useAuth();
    const location = useLocation();
    const pendingSubscription = localStorage.getItem('pendingSubscription');
    const isRegistrationSuccess = location.pathname === '/registration-success';

    if (isAuthenticated) {
        // If there's a pending subscription or it's the registration success page, don't redirect
        if (pendingSubscription || isRegistrationSuccess) {
            return children;
        }
        return <Navigate to="/dashboard" state={{ from: location }} replace />;
    }
    return children;
};

export const ProtectedAdminRoute = ({ children }) => {
    const { isAdmin } = useAuth();
    const location = useLocation();

    // Only check admin-specific authentication
    if (!isAdmin) {
        return <Navigate to="/admin" replace />;
    }

    return children;
};